import './App.css';
import { Routes, Route } from 'react-router-dom';
import Layout from '../src/Components/NavBarComponent/Layout';
import Home from '../src/Components/Home/Home';
import ProductTemplate from '../src/Routes/ProductPage';
import CollectionPage from '../src/Routes/CollectionPage';
import AboutUsPage from '../src/Routes/AboutUsPage';
import ContactUsPage from './Routes/ContactUsPage';
import Accounts from './Routes/Accounts';
import Footer from './Components/Footer/Footer';
import CreateProduct from './Components/Account/CreateProduct';
import EditUserDetails from './Routes/EditUserDetails';
import MockAccount from './Components/Account/MockAccount';
import EditProduct from './Components/Account/EditProduct';
import {React,useState, useEffect,useRef, startTransition} from "react";
import Api from '../src/AxiosRest/AxiosRest';
import MLogin from './Components/Manager/M_Login';
import MHome from './Components/Manager/ManagerHome';
import CompanyIndex from './Components/Documentation/CompanyIndex';

import "./Fonts/Urbanist-Bold.ttf";
import "./Fonts/Urbanist-Light.ttf";
import "./Fonts/Urbanist-Medium.ttf";

//State imports
import { useSelector, useDispatch } from 'react-redux';
import { Login, CurrentUser, Product } from '../src/Actions';

function App() {

  //creating instances of various methods that I will be calling
  let dispatch = useDispatch();

  //Firing API call to recieve full list of available products
  /*let GetProducts = async() =>{
    try{
        await Api.get('/store/Products/GetProducts').then((response) => {
            dispatch(Product(response.data));
        });
    }
    catch(exception){   
        alert("Connection Error");
    }  
  }

  //used to fire api call as soon as the page loads
  useEffect(() => {
    GetProducts();
  }, []);*/

  return (
    <div className="App">
      <div className="Page_Wrap">
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="Product" element={<ProductTemplate />} />
            <Route path="Collection" element={<CollectionPage />} />
            <Route path="AboutUs" element={<AboutUsPage/>} />
            <Route path="ContactUs" element={<ContactUsPage/>} />
            <Route path="Account" element={<Accounts/>} /> 
            <Route path="CreateProduct" element={<CreateProduct/>} /> 
            <Route path="EditDetails" element={<EditUserDetails/>} /> 
            <Route path="UserStore" element={<MockAccount/>}/>
            <Route path="EditProduct" element={<EditProduct/>}/>
            <Route path="Manager" element={<MLogin/>}/>
            <Route path="ManagerHome" element={<MHome/>}/>
            <Route path="CompanyIndex" element={<CompanyIndex/>}/>
            <Route path="index.html" element={<Home/>}/>
          </Route>
        </Routes>
        <Footer></Footer>
      </div> 
    </div>
  );
}

export default App;
