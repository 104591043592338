import React, { useState, useEffect } from 'react';
import Default from '../../../../src/Images/Default.png';
import '../FeaturedContent/FeaturedContent.css';
import { Carousel } from 'react-responsive-carousel';
import Api from '../../../AxiosRest/AxiosRest';
import styles from 'react-responsive-carousel/lib/styles/carousel.min.css';
import SCNetTree from "../../../Images/SCNetTreeLogo.png";
import SCNetTreeBig from "../../../Images/SCNetLogoGrey.png";

//State imports
import { useSelector, useDispatch } from 'react-redux';
import { Login, CurrentUser, SelectedStore } from '../../../Actions';

function FeaturedContent(){

    const [FeaturedSuppliers, setFeaturedSuppliers] = useState([]);
    const [FMedium, setMedium] = useState([]);
    const [FLarge, setLarge] = useState([]);
    const [FSmall1, setSmall1] = useState([]);
    const [FSmall2, setSmall2] = useState([]);
    let FSmallFull = [];


    let dispatch = useDispatch();


    //Firing API call to recieve full list of available stores
    let GetFeaturedSuppliers = async() =>{
        try{
            await Api.get('/store/FeaturedSupplier/GetAllFeaturedSuppliers').then((response) => {
                setFeaturedSuppliers(response.data);
                FSmallFull = response.data.filter((item) => item.sizeID == 1);
                setMedium(response.data.filter((item) => item.sizeID == 2));
                setLarge(response.data.filter((item) => item.sizeID == 3));
                let half = Math.ceil(FSmallFull.length / 2); 
                setSmall1(FSmallFull.slice(0, half));
                setSmall2(FSmallFull.slice(half));
            });
        }
        catch(exception){   
            
        }  
    } 

    useEffect(() => {
        GetFeaturedSuppliers();
    }, []);

    return(
        <div>
            <div className="GradientBackground">
                <h1>Featured Store</h1>
                <div id="FeaturedContent">
                    <div className="FC_Images_Wrap">
                        <div className="FC_Images_Left">
                            {FMedium != "" ?
                                <div>
                                    <Carousel showThumbs={false}>
                                    {
                                        FMedium.map((item, index) => {
                                            <div>
                                                <div>
                                                    <img src={FMedium.featuredImage}/>
                                                </div>
                                                <div>
                                                    <h3>{item.aboutStore}</h3>
                                                </div> 
                                            </div>             
                                        })
                                    }
                                    </Carousel>
                                </div>
                                :
                                <div>
                                    <img src={SCNetTreeBig} height="200px" width="350px"/>
                                </div>
                            }   
                        </div>
                        <div className="FC_Images_Right">
                            {FSmall1 != "" ?
                                <div>
                                    <Carousel showThumbs={false}>
                                        {    
                                            FSmall1.map((item,index) =>(
                                                <div>
                                                    <div className="FC_Images_Right_Top">
                                                        <img src={item.featuredImage} height="200px"/>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </Carousel>
                                </div>
                                :
                                <div>
                                    <div className="FC_Images_Right_Top">
                                        <img src={SCNetTree} height="200px"/>
                                    </div>
                                </div>
                            }
                            {FSmall2 != "" ?
                                <div>
                                    <Carousel showThumbs={false}>
                                        {
                                            FSmall2.map((item,index) =>{
                                                <div className="FC_Images_Right_Top">
                                                    <img src={item.featuredImage} height="200px"/>
                                                </div>
                                            })
                                        }
                                    </Carousel>
                                </div>
                                :
                                <div>
                                    <div className="FC_Images_Right_Bottom">
                                        <img src={SCNetTree} height="200px"/>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="FeaturedContent_Text_Wrap"> 
                        <div  className="FeaturedContent_Inner">
                            {FLarge != "" ?
                                <div>  
                                    <Carousel>
                                        {FLarge.map((item, index) => {
                                            <div className="FC_Large_Wrap">
                                                <div className="FC_Large_Title_Image">
                                                    <div className="FC_Large_Image">
                                                        <img src={item.featuredImage}/>
                                                    </div>
                                                    <div className="FC_Large_Title">
                                                        <h2>{item.storeName}</h2>
                                                    </div>
                                                </div>
                                                <div className="FC_Large_Desc">
                                                    <p>{item.aboutStore}</p>
                                                </div>
                                            </div>
                                        })}   
                                    </Carousel>
                                </div>
                                :
                                <div>
                                    <div className="FC_Large_Wrap">
                                        <div className="FC_Large_Title_Image">
                                            <div className="FC_Large_Image">
                                                <img src={SCNetTree}/>
                                            </div>
                                            <div className="FC_Large_Title">
                                                <h2>Supply Chain Network</h2>
                                            </div>
                                        </div>
                                        <div className="FC_Large_Desc">
                                            <p>SCNet provides procurement solutions to assist buyers to discover credible and empowered suppliers, with powerful and secure online quote management technology.</p>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>                   
                    </div>
                </div>
                <div className="Wave_Wrap">
                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                </div>
            </div>
        </div>    
    );
}

export default FeaturedContent;