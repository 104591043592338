import {useNavigate} from 'react-router-dom';
import {React,useState, useEffect} from "react";
import Api from '../../AxiosRest/AxiosRest';
import "../Account/Accounts.scss"
import Swal from 'sweetalert2';
import SCNetTreeLogo from '../../Images/SCNetTreeLogo.png';

//State imports
import { useSelector, useDispatch } from 'react-redux';
import { CurrentUser, CurrentProduct} from '../../Actions';

function AccountHome() {
    let dispatch = useDispatch();
    const navigate = useNavigate();
    let UserDetails = useSelector(state => state.CurrentUser);
    let [UserProducts, setUserProducts] = useState([]);
    let LoggedInUser = "";

    let date = new Date();

    let FetchUserProducts = async () => {
        try{
            Swal.fire({
                title: 'Loading',
                html: '<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>',
                showCancelButton: false,
                showConfirmButton: false,
                allowOutsideClick: false,
                didOpen: () => {
                  Swal.showLoading();
                }
            });
            Api  
            .post('/store/Products/GetUserCatalog', 
                {id : LoggedInUser.id}
            )
            .then((response) => {
                setUserProducts(response.data.products);
                //dispatch(CurrentUser(LoggedInUser));
                Api.post('/store/StoreUser/GetUserDetails', 
                    {id: LoggedInUser.id}
                )
                .then((response) => {
                    dispatch(CurrentUser(response.data));
                    Swal.close();
                })
                .catch((response) => {
                    Swal.close();
                    Swal.fire(
                        'error',
                        'We could not fetch your details, please try again later',
                        'error'
                    )
                })
            })
            .catch((response) => {
                Swal.close();
                Swal.fire(
                    'error',
                    'We encountered and error, please try again',
                    'error'
                )
            })
        }
        catch(error){
            Swal.close();
            navigate("/");
        }
        
    }

    function DeleteProduct(index){
        Swal.fire({
            title: 'Are you sure you want to delete this product?',
            showCancelButton: true,
            confirmButtonText: 'Delete',
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: 'Loading',
                    html: '<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>',
                    showCancelButton: false,
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    didOpen: () => {
                      Swal.showLoading();
                    }
                });
                try{
                    Api.post('store/Products/DeleteProduct', {ID : UserProducts[index].productID})
                    .then((response) => {
                        setUserProducts(UserProducts.slice(0, index).concat(UserProducts.slice(index+1)));
                        Swal.close()
                        Swal.fire(
                            'success',
                            'This product has beent successfully deleted',
                            'success'
                        )
                    })
                    .catch((response) => {
                        Swal.close();
                        Swal.fire(
                            'error',
                            'There was an error deleting your product',
                            'error'
                        )
                    })
                }catch(exception){
                    Swal.close();
                    Swal.fire(
                        'error',
                        'There was an error deleting that product',
                        'error'
                    )
                }
            }
        })
    }

    

    let Validate = () => {
        LoggedInUser = JSON.parse(window.sessionStorage.getItem('LoggedInUser')); 
        if(LoggedInUser){
            FetchUserProducts();
        }
        else{
            alert("Please log in to access this page");
            navigate("/");
        }
    }

    function ScrollToTop(){
        window.scrollTo({
          top: 0,
          behavior: 'instant',
        })
    }

    
    const CreateProdPage = () => {
        navigate('/CreateProduct');
    }

    const EditUserDetails = () => {
        navigate('/EditDetails');
    }

    useEffect(() => {
        Validate();
        ScrollToTop();
    }, []);
    

    return(
        <div id="AccountsHome">
            <div className="Accounts_Wrapper">
                <div className="Account_Home_Info_Wrap">
                    <div className="Account_Home_Info">
                        <div className="Account_Info">
                            <div>
                                {!UserDetails.storeName ? 
                                    <h1>{UserDetails.firstName} {UserDetails.surname}</h1>
                                :
                                    <h1>{UserDetails.storeName}</h1>
                                } 
                            </div>
                            <div>
                                <p>{UserDetails.aboutStore}</p>
                            </div>
                            <div className="StoreStatus">
                                <p><strong>Account Status:</strong> {UserDetails.statusDescription}</p>
                            </div>
                        </div>
                        <div className="Account_CompanyLogo_Wrap">
                            <div>
                                <img src={UserDetails.companyLogo} width="200px" alt="CompanyLogo"/>
                            </div>
                        </div>
                    </div>    
                </div>
                <div className="Account_Home_Content_Wrap">
                    <div className="Account_Button_Wrap">
                        <div>
                            <button className="BtnGrey" onClick={CreateProdPage}>CREATE</button>
                        </div>
                        <div>
                            <button className="BtnGrey" onClick={EditUserDetails}>EDIT DETAILS</button>
                        </div>
                    </div>
                    <div className="Account_Prod_Card_Wrap">
                    {!UserProducts ?
                            <div>
                                <h3>Sorry there are currently no products to show, please come back soon.</h3>
                            </div>    
                        :
                            <div>
                                {UserProducts.map((item, index) => (
                                    <div key={index} id="Prod_Card">
                                        <div className="Prod_Card">
                                            {date.getTime() >= Date.parse(item.startDate) && Date.parse(item.endDate) >= date.getTime() && item.comparePrice > 0 ?
                                                <div class="ribbon"><span>SALE</span></div>
                                                :
                                                <div></div>
                                            }
                                            <div className="Prod_Card_Img" onClick={()=>{ dispatch(CurrentProduct(UserProducts[index])); navigate("/Product");}}>
                                                
                                                { item.imagesUrls != "" ?
                                                    <div>
                                                        { item.imagesUrls[0].includes("SCNetTreeLogo") ?
                                                            <div>
                                                                { UserDetails.companyLogo != "" ?
                                                                    <img src={UserDetails.companyLogo} width="150px" height="150px" alt='CompanyLogo'/>
                                                                    :
                                                                    <img src={SCNetTreeLogo} width="150px" height="150px" alt='ProductFeatureImage'/>
                                                                }
                                                            </div>
                                                            :
                                                            <img src={item.imagesUrls[0]} width="150px" height="150px" alt='ProductFeatureImage'/>
                                                        }
                                                    </div>   
                                                    :
                                                    <div>
                                                        { UserDetails.companyLogo != "" ?
                                                            <img src={UserDetails.companyLogo} width="150px" height="150px" alt='CompanyLogo'/>
                                                            :
                                                            <img src={SCNetTreeLogo} width="150px" height="150px"/>
                                                        }
                                                    </div>
                                                }
                                                
                                            </div>    
                                            <div className="Prod_Card_Text">
                                                <h4 onClick={()=>{
                                            dispatch(CurrentProduct(UserProducts[index]));
                                            navigate("/Product");}}>{item.productName}</h4>
                                            <p id="Account_Price"><strong>R</strong> {item.productPrice} 
                                            {date.getTime() >= Date.parse(item.startDate) && Date.parse(item.endDate) >= date.getTime() && item.comparePrice > 0 ?
                                                <a> Price: R{item.comparePrice}</a>
                                            :
                                                <a></a>
                                            }</p>
                                            <p className="Prod_Card_Desc">{item.productDescription}</p>
                                            </div>
                                            <div className="Prod_Card_Btns">
                                                <button onClick={()=>{ 
                                                        dispatch(CurrentProduct(item)); navigate("/EditProduct");
                                                    }} 
                                                    className="Prod_Card_Edit BtnGrey">
                                                    Edit
                                                </button>
                                                <button onClick={()=>{ 
                                                        DeleteProduct(index);
                                                    }} 
                                                    className="Prod_Card_Delete BtnGrey">
                                                    Delete Product
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>  
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AccountHome;