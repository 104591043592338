import './Manager.scss';
import {React,useState, useEffect,useRef, startTransition} from "react";
import { RiCloseLine } from "react-icons/ri";
import Api from '../../AxiosRest/AxiosRest';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'


//State imports
import { useSelector, useDispatch } from 'react-redux';
import { Login, CurrentUser, SelectedStore } from '../../Actions';

const FeaturedModel = ({ FeaturedIsOpen }) => {

    const MAX_COUNT = 1; //this is for the max number of images that can be uploaded

    const dispatch = useDispatch();
    let navigate = useNavigate();

    const [StoreTitle, setStoreTitle] = useState("");
    const [StoreDescription, setStoreDescription] = useState("");
    const [FeaturedSection, setFeaturedSection] = useState("");
    const [CompanyLogo, setCompanyLogo] = useState("");
    const [defaultCompanyLogo, setDefaultCompanyLogo] = useState("");
    const [StoreURL, setStoreURL] = useState("");
    const [checked, setChecked] = useState("");
    const [fileLimit, setFileLimit] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    let selectedStore = useSelector(state => state.SelectedStore);
    let CurrentUser = useSelector(state => state.CurrentUser);

    let FeaturedStore = new FormData();


    function SetFeaturedStore(){
      startDate.setHours(0,0,0,0);
      endDate.setHours(0,0,0,0);

      if(startDate.getTime() < endDate.getTime()){

        FeaturedStore.append('adminID', CurrentUser.adminID);
        FeaturedStore.append('catalogID', selectedStore.catalogID);
        FeaturedStore.append('storeName', StoreTitle);
        FeaturedStore.append('storeDescription', StoreDescription);
        FeaturedStore.append('storeURL', StoreURL);
        FeaturedStore.append('sizeID', FeaturedSection);
        FeaturedStore.append('startDate', startDate.toISOString());
        FeaturedStore.append('endDate', endDate.toISOString());
        for (const image of CompanyLogo){
          FeaturedStore.append("FeaturedImage", image);
        }
  
        Api.postForm('/store/FeaturedSupplier/AddNewFeaturedSupplier', FeaturedStore, {
            headers: {
                'Content-Type': 'multipart/form-data'
              }
            }
          )
          .then((response) => {
            Swal.fire(
              'Great!',
              'Store has been set!',
              'success'
            )
            FeaturedIsOpen(false);
        })
        .catch((response) => {
          Swal.fire(
            'Error!',
            'There was an error setting the featured supplier',
            'error'
          )
        })
      }
      else{
        Swal.fire(
          'error!',
          'Please ensure the start date is before the end date',
          'error'
        )
      }
    }

    //this method will accept an array of images and filter that array to determine if it has exceeded the limit of images allow to submit
    const handleImageChange = files => {
      const uploaded = [...CompanyLogo];
      let limitExceeded = false;
      files.some((file) => {
          if(uploaded.findIndex((f) => f.name === file.name) === -1){
              uploaded.push(file);
              if(uploaded.length === MAX_COUNT) setFileLimit(true);
              if(uploaded.length > MAX_COUNT){
                  alert('You can only add a maximum of 1 files');
                  setFileLimit(false);
                  limitExceeded = true;
                  return true;
              }
          }
      })
      if (!limitExceeded) setCompanyLogo(uploaded)
    }


    //this method will accept each image as it is uploaded and send it to the HandleImageChange method to handle
    const handleLogoFileEvent = (e) => {
      const chosenFiles = Array.prototype.slice.call(e.target.files)
      handleImageChange(chosenFiles);
    }
    
    function DefaultValues(){
      setStoreTitle(selectedStore.storeName);
      setStoreDescription(selectedStore.aboutStore);
      setFeaturedSection("");
      setDefaultCompanyLogo(selectedStore.companyLogo);
      setStoreURL("NA");
    }

    //used to fire api call as soon as the page loads
    useEffect(() => {
      DefaultValues();
    }, []);
  
    return (
      <>
        <div className="darkBG" onClick={() => FeaturedIsOpen(false)} />
        <div className="M_FeaturedCentered" >
          <div className="Modal">
            <div className="modalHeader">
              <h3 className="heading">Set Featured Supplier</h3>
            </div>
            <button className="closeBtn" onClick={() => FeaturedIsOpen(false)}>
              <RiCloseLine style={{ marginBottom: "-3px" }} />
            </button>
            <div className="modalContent">
                <div className="M_Featured_Choices_Wrap">
                  <label for="Role" className="lbl_M_Edit_Role">Featured Supplier Section</label> 
                  <select name="Edit_DropDown_Role" id="M_Edit_DropDown_Role" onChange={(event)=> {setFeaturedSection(event.target.value)}}> 
                    <option value="" selected>Featured Sections</option>
                    <option value="1">Small</option>
                    <option value="2">Medium</option>
                    <option value="3">Large</option>
                  </select>
                </div>
                
                <div className="M_FeaturedSmall_Wrap" aria-hidden={FeaturedSection !== "1" ? true : false}>
                  <div className="M_F_Logo_Wrapper">      
                    <div className="M_F_Logo_Inputs">
                        <label for="M_F_Logo" className="lbl_M_F_Logo">Store Logo:</label> 
                        <input type="file" id="M_F_Logo" name="M_F_Logo" multiple onChange={handleLogoFileEvent} accept="image/png"/>
                    </div>
                  </div>
                  <div className="M_F_URL_Wrap">
                    <label for="M_F_StoreURL" className="lbl_M_F_StoreURL">Store URL?:</label> 
                    <input id="M_F_StoreURL" name="M_F_StoreURL" className="M_F_StoreURL" type="text" placeholder="Store URL" onChange={(event) => {setStoreURL(event.target.value)}}/>
                  </div>
                  <div className="M_F_StartDate_Wrap">
                    <label for="M_F_StartDate" className="lbl_M_F_StartDate">Start Date:</label> 
                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                  </div>
                  <div className="M_F_EndDate_Wrap">
                    <label for="M_F_EndDate" className="lbl_M_F_EndDate">End Date:</label> 
                    <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
                  </div>
                </div>

                <div className="M_FeaturedMedium_Wrap" aria-hidden={FeaturedSection !== "2" ? true : false}>
                  <div className="M_F_Title_Wrap">
                      <label for="M_F_Title" className="lbl_M_Featured_Title">Store Title:</label> 
                      <input id="M_F_Title" name="M_F_Title" className="M_Featured_Title" type="text" defaultValue={selectedStore.storeName} placeholder="Username" onChange={(event) => {setStoreTitle(event.target.value)}}/>
                  </div>
                  <div className="M_F_Logo_Wrapper">          
                    <div className="M_F_Logo_Inputs">
                        <label for="M_F_Logo" className="lbl_M_F_Logo">Store Logo:</label> 
                        <input type="file" id="M_F_Logo" name="M_F_Logo" onChange={handleLogoFileEvent} accept="image/png"/>
                    </div>
                  </div>
                  <div className="M_F_URL_Wrap">
                    <label for="M_F_StoreURL" className="lbl_M_F_StoreURL">Store URL?:</label> 
                    <input id="M_F_StoreURL" name="M_F_StoreURL" className="M_F_StoreURL" type="text" placeholder="Store URL" onChange={(event) => {setStoreURL(event.target.value)}}/>
                  </div>
                  <div className="M_F_StartDate_Wrap">
                    <label for="M_F_StartDate" className="lbl_M_F_StartDate">Start Date:</label> 
                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                  </div>
                  <div className="M_F_EndDate_Wrap">
                    <label for="M_F_EndDate" className="lbl_M_F_EndDate">End Date:</label> 
                    <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
                  </div>
                </div>

                <div className="M_FeaturedLarge_Wrap" aria-hidden={FeaturedSection !== "3" ? true : false}>
                  <div className="M_F_Title_Wrap">
                      <label for="M_F_Title" className="lbl_M_Featured_Title">Store Title:</label> 
                      <input id="M_F_Title" name="M_F_Title" className="M_Featured_Title" type="text" defaultValue={selectedStore.storeName} placeholder="Username" onChange={(event) => {setStoreTitle(event.target.value)}}/>
                  </div>
                  <div className="M_F_Desc_Wrap">
                      <label for="M_F_Description" className="lbl_M_F_Description">Description:</label> 
                      <input id="M_F_Description" name="M_F_Description" className="M_F_Description" type="text" defaultValue={selectedStore.aboutStore} onChange={(event) => {setStoreDescription(event.target.value)}} placeholder="Description" required />
                  </div>
                  <div className="M_F_Logo_Wrapper">     
                    <div className="M_F_Logo_Inputs">
                        <label for="M_F_Logo" className="lbl_M_F_Logo">Store Logo:</label> 
                        <input type="file" id="M_F_Logo" name="M_F_Logo" onChange={handleLogoFileEvent} accept="image/png"/>
                    </div>
                  </div>
                  <div className="M_F_URL_Wrap">
                    <label for="M_F_StoreURL" className="lbl_M_F_StoreURL">Store URL?:</label> 
                    <input id="M_F_StoreURL" name="M_F_StoreURL" className="M_F_StoreURL" type="text" placeholder="Store URL" onChange={(event) => {setStoreURL(event.target.value)}}/>
                  </div>
                  <div className="M_F_StartDate_Wrap">
                    <label for="M_F_StartDate" className="lbl_M_F_StartDate">Start Date:</label> 
                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                  </div>
                  <div className="M_F_EndDate_Wrap">
                    <label for="M_F_EndDate" className="lbl_M_F_EndDate">End Date:</label> 
                    <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
                  </div>
                </div>
            </div>
            <div className="modalActions">
              <div className="actionsContainer">
                <button className="BtnGrey BtnSetFeatured" onClick={() => SetFeaturedStore()}>
                  Set Featured
                </button>
                <button className="LogincancelBtn BtnLightGrey" onClick={() => FeaturedIsOpen(false)}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

export default FeaturedModel;
