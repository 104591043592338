

import {React,useState, useEffect} from "react";
import LoginPopUp from "../Home/Login/LoginPopUp";
import Cart from "../Cart/Cart";
import '../Home/Login/Login.scss';
import { useNavigate, useLocation  } from 'react-router-dom';
import Registration from '../Home/Login/Registration';
import TreeLogo from '../../Images/SCNetTreeLogo.png';
import MobileSideBar from './MobileSideBar';
import Swal from 'sweetalert2';

import { useSelector, useDispatch } from 'react-redux';
import { CurrentUser } from '../../Actions';

const Navbar = () => {

  const [isOpenLogin, loginIsOpen] = useState(false);
  const [isOpenCart, CartIsOpen] = useState(false);
  const [RegisterIsOpen, OpenRegister] = useState(false);
  const dispatch = useDispatch();
  let LoggedInUser = JSON.parse(window.sessionStorage.getItem('LoggedInUser')); 
  let navigate = useNavigate();
  const location = useLocation();

  function Navigate(){
    if(LoggedInUser === ""){
      alert("You need to login first")
      loginIsOpen(true);
    }
    else{
      navigate("/Account");
    }
  }

  function Logout(){
    let empty = " ";
    dispatch(CurrentUser(empty));
    window.sessionStorage.removeItem('LoggedInUser');
    navigate("/");
    document.getElementById("Main_Nav_Menu").style.display = "flex";

    Swal.fire(
      'Success',
      'Successfully logged out',
      'success'
    )
  }

  function RenderNav(){
    if(location.pathname === "/ManagerHome"){
        let Nav_Wrap = document.getElementById("Main_Nav_Menu");
        Nav_Wrap.style.display = "none";
    }
  }

  function RenderCSSFixes(){
      let bm_Menu = document.getElementsByClassName("bm-menu");
      bm_Menu[0].setAttribute("style", "overflow:none; height: 100%; box-sizing: border-box;");
      let bm_item = document.getElementsByClassName("bm-item");
      bm_item[0].setAttribute("style", "display: flex; flex-direction: column;");
  }

  useEffect(() => {
    RenderNav();
    RenderCSSFixes();
  }, []);

  return (
    <div className="Nav_Menu_Wrapper" id="Nav_Menu_Wrapper">
      <nav className="Menu_Wrap">
        <div id="MobileNavBar">
          <MobileSideBar pageWrapId={'Layout_Content'} outerContainerId={'Nav_Menu_Wrapper'}/>
        </div>
        <ul id="Main_Nav_Menu" className="menus">     
          <li><a onClick={() => navigate("/")}>Home</a></li>
          <li><a onClick={() => navigate("Collection")}>Products</a></li>
          <li><a onClick={() => navigate("AboutUs")}>About Us</a></li>
          <li><a onClick={() => navigate("ContactUs")}>Contact Us</a></li>
          <li><a onClick={() => navigate("CompanyIndex")}>Index</a></li>
        </ul>
      </nav>
      <div className="Menu_Title">
        <a href="/"><h1>SCNet Deals</h1> <img src={TreeLogo} width="100px"/></a>
      </div>
      <div className="Menu_Buttons_Wrap">
        <div className="LoginBtn_Wrap">
            
        </div>
        {!LoggedInUser ? 
          <div className="NotLoggedInWrap">
            <div>
              <button className="LoginBtn BtnGrey" onClick={() => loginIsOpen(true)}>
                  Login
              </button>
              {isOpenLogin && <LoginPopUp loginIsOpen={loginIsOpen}/>}
              <button id="SignUpBtn" className="SignUp_Btn BtnGrey" onClick=  {() => OpenRegister(true)}>
                  Sign Up
              </button>
              {RegisterIsOpen && <Registration OpenRegister={OpenRegister}/>}
            </div>  
          </div> 
        : 
          <div>
            <div className="Account_Btns_Wrap">
              {LoggedInUser.roleID == undefined ?
                <button className="AccountBtn BtnGrey" onClick={Navigate}>
                My Account
                </button>
                :
                <a></a>
              }
              
              <button className="AccountLogout BtnGrey" onClick={Logout}>
                  Logout
              </button>
            </div>        
          </div>
        }
      </div>
    </div>
  );
};

export default Navbar;
