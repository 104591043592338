import {React,useState, useEffect} from "react";
import '../ProductTemplate.scss';
import Default from '../../../Images/Default.png';
import { Carousel } from 'react-carousel-minimal';
import { useNavigate } from 'react-router-dom';
import CollectionMessagePop from '../../Collection/collecttionMessagePop';

//State imports
import { useSelector, useDispatch } from 'react-redux';
import { Login, CurrentUser, CurrentProduct } from '../../../Actions';

function ProductInfo() {

  let dispatch = useDispatch();
  let navigate = useNavigate();
  let Products = useSelector(state => state.Product);
  const [isOpenMessage, MessageIsOpen] = useState(false);

  let Images = [];
  let data = [];

  let CurrentProduct = useSelector(state => state.CurrentProduct);

  let Validate = () => {
    if(CurrentProduct == " "){
        alert("Error fetching selected product");
        navigate("/Collection");
    }
  }

  function ScrollToTop(){
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    })
  }

  {
    if(CurrentProduct.imagesUrls.length != 0){
      CurrentProduct.imagesUrls.map((item, index) => (
        data.push({image: item})
      ))
    }else{
      data.push({image: CurrentProduct.companyLogoUrl})
    }
  }

  const captionStyle = {
    fontSize: '2em',
    fontWeight: 'bold',
  }

  const slideNumberStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
  }

  useEffect(() => {
    ScrollToTop();
  }, []);

  return (
    <div id="Product_Wrap" onLoad={Validate}>
      <div className="Product_Inner">
        <div className="Product_Carousel_Col">
          <Carousel
              data={data}
              time={2000}
              width="850px"
              height="500px"
              captionStyle={captionStyle}
              radius="10px"
              slideNumber={true}
              slideNumberStyle={slideNumberStyle}
              captionPosition="bottom"
              automatic={false}
              dots={true}
              pauseIconColor="white"
              pauseIconSize="40px"
              slideBackgroundColor="darkgrey"
              slideImageFit="cover"
              thumbnails={true}
              thumbnailWidth="100px"
              style={{
              textAlign: "center",
              maxWidth: "850px",
              maxHeight: "500px",
              margin: "40px auto",
            }}/>
        </div>
        <div className="Product_Info_Col">
          <div>
            <h1>{CurrentProduct.productName}</h1>
            <h2>Price: R{CurrentProduct.productPrice}</h2>
            <div className="Prod_BuyNow_Quantity">
              <div className="Prod_Quantity">
              </div>
              <div className="Prod_Btn_Wrap">
                <button id="Prod_AddCart_Btn" onClick={() => {MessageIsOpen(true);}}>View Details</button>
                {isOpenMessage && <CollectionMessagePop MessageIsOpen={MessageIsOpen}/>}
              </div>
            </div>
            <div className="Prod_Description">
              <ul>
                <li>
                  <a>Description</a> 
                </li>
              </ul>
              <p>{CurrentProduct.productDescription}</p>
            </div>
          </div>       
        </div>
      </div>
    </div>
  );
}

export default ProductInfo;