import '../Login/Login.scss';
import {React,useState, useEffect,useRef, startTransition} from "react";
import { RiCloseLine } from "react-icons/ri";
import Api from '../../../AxiosRest/AxiosRest';
import { useNavigate } from 'react-router-dom';
import TreeLogo from '../../../Images/SCNetTreeLogo.png';
import Swal from 'sweetalert2';


//State imports
import { useSelector, useDispatch } from 'react-redux';
import { Login, CurrentUser } from '../../../Actions';

const LoginPopUp = ({ loginIsOpen }) => {

    const dispatch = useDispatch();
    let CurrentUser1;
  
    const [isLogin, setIsLogin] = useState(true);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [user, setUser] = useState([]);
    let navigate = useNavigate();
    let LoggedInUser;

    // fire login api call to send data and return current user object
    let LoginUser =  () => {
      Swal.fire({
        title: 'Loading',
        html: '<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>',
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });
      Api.post('/store/StoreUser/Login', {
        username: username,
        Password: password,
      }).then((response) => {
        dispatch(CurrentUser(response.data));
        dispatch(Login(true));
        setUser(response.data);
        loginIsOpen(false);
        LoggedInUser = response.data;
        window.sessionStorage.setItem('LoggedInUser', JSON.stringify(LoggedInUser));
        Swal.close();
        Swal.fire(
          'Welcome',
          '',
          'success'
        )
      }).catch(function (error){
        Swal.close();
        Swal.fire(
          'Error',
          'Incorrect Username and Password!',
          'error'
        )
        setUsername("");
        setPassword("");
      })
    };

    let ResetPassword = () => {
      Swal.fire({
        title: 'Loading',
        html: '<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>',
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });
      Api.post('/store/StoreUser/ResetUserPassword', {
        username: username
      }).then((response) => {
        Swal.close();
        Swal.fire(
          'Great!',
          'Password has been reset!',
          'success'
        )
      }).catch(function (error){
        Swal.close();
        Swal.fire(
          'Error',
          'Error resetting password!',
          'error'
        )
      })
    }

  
    return (
      <>
        <div className="darkBG" onClick={() => loginIsOpen(false)} />
        <div className="LoginCentered" >
          <div className="Modal">
            <div className="modalHeader">
              <h3 className="heading">LOGIN</h3>
            </div>
            <button className="closeBtn" onClick={() => loginIsOpen(false)}>
              <RiCloseLine style={{ marginBottom: "-3px" }} />
            </button>
            <div className="modalContent">
              <label for="LoginEmail" className="">Username</label> 
              <input value={username} 
                onChange={(event) => {
                  setUsername(event.target.value);
                }} id="LoginUsername" className="" type="text" placeholder="Username" required />
  
              <label for="LoginPassword" className="">Password</label> 
              <input value={password} 
              
                onChange={(event) => {
                  setPassword(event.target.value);
                }} id="LoginPassword" className="" type="password" placeholder="Password" required />
  
            </div>
            <div className="modalActions">
              <div className="actionsContainer">
                <button 
                  onClick={ LoginUser } className="loginBtn BtnGrey">
                    Login
                </button>
                <button onClick={ ResetPassword } className="ResetPassBtn BtnGrey">
                  Reset Password
                </button>
                <button className="LogincancelBtn BtnLightGrey" onClick={() => loginIsOpen(false)}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

export default LoginPopUp;
