import Collection from '../Components/Collection/Collection';

function CollectionPage() {
    return (
      <div>
          <Collection/>
      </div>
    );
  }
  
  export default CollectionPage;