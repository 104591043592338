import '../Manager/Manager.scss';
import Api from '../../AxiosRest/AxiosRest';
import { useNavigate } from 'react-router-dom';
import {React,useState, useEffect,useRef, startTransition} from "react";
import RejectStoreModel from './RejectStoreModel';
import ApproveStoreModel from './ApproveStoreModel';

//State imports
import { useSelector, useDispatch } from 'react-redux';
import { Login, CurrentUser, Product, AddToCart, CurrentProduct, FilteredProduct, AllStores, FilterStores, SelectedStore} from '../../Actions';

function Store_Approvals(){

    const [SearchTerm, setSearchTerm] = useState("");
    const [DefaultSortedStores, setDefaultSortedStores] = useState([]);
    const [isOpenApproveStore, MApproveStoreIsOpen] = useState(false);
    const [isOpenRejectStore, MRejectStoreIsOpen] = useState(false);

    let dispatch = useDispatch();
    let navigate = useNavigate();

    let allStores = [];
    let allProducts = [];
    let ToggleFilterID = ""

    //fetching required data from redux
    allStores = useSelector(state => state.AllStores);
    let FilteredStores = useSelector(state => state.FilteredStores);
    allProducts = useSelector(state => state.Product);


    //Logic for applying search filter to the shown stores
    function Search(){
        try{
            if(SearchTerm != ""){
                dispatch(FilterStores(allStores.filter((item) => item.storeName.toLowerCase().includes(SearchTerm.toLowerCase()))));
                FilteredStores = allStores.filter((item) => item.storeName.toLowerCase().includes(SearchTerm.toLowerCase()));
            }
            else{
                dispatch(FilterStores(""));
            } 
        }
        catch(error){
            
        } 
    }

    //Building the default structure for the displayed stores in the required order using spread
    function BuildOrderedStores(){
        let approvedstores = allStores.filter(item => item.isApproved === 2);
        let awaitingApproval = allStores.filter(item => item.isApproved === 1);
        let declinedApproval = allStores.filter(item => item.isApproved === 3);
        setDefaultSortedStores([...awaitingApproval, ...approvedstores, ...declinedApproval]);

        let FilterDropValue = document.getElementById("M_SA_StatusDrop").value;

        switch(FilterDropValue) {
            case '0':
              return dispatch(FilterStores([...awaitingApproval, ...approvedstores, ...declinedApproval]));
            case '1':
              return dispatch(FilterStores(awaitingApproval));
            case '2':
              return dispatch(FilterStores(approvedstores));
            case '3':
              return dispatch(FilterStores(declinedApproval));   
            default:
              return dispatch(FilterStores(""));
        }
    }

    function ResetFilter(){
        dispatch(FilterStores(""));
    }

    function ScrollToTop(){
        window.scrollTo({
          top: 0,
          behavior: 'instant',
        })
      }


    //used to fire api call as soon as the page loads
    useEffect(() => {
        ScrollToTop();
        BuildOrderedStores();
        ResetFilter();
        Search();
    }, []);

    return(
        <div id="Store_approvals">
            <div>
                <div className="M_SA_Search">
                    <input value={SearchTerm} 
                    onChange={(event) => { setSearchTerm(event.target.value);}} className="M_SA_SearchTerm" type="text" placeholder="Store Name" required />
                    <button className="BtnGrey" onClick={() => {Search()}}>Search</button>
                    <select name="M_SA_StatusDrop" className="BtnGrey" id="M_SA_StatusDrop" onChange={() => { BuildOrderedStores() }}>
                        <option value="0" selected>Store Status:</option>
                        <option value="1">Awaiting Approval</option>
                        <option value="2">Approved Stores</option>
                        <option value="3">Rejected Stores</option>
                    </select>
                </div>
                <div>
                    <div>
                        {!FilteredStores ?   
                            <div>
                                {!DefaultSortedStores ?
                                    <div>
                                        <h3>There are currently no stores in need of approval</h3>
                                    </div>
                                    :
                                    <div className="M_SA_Stores_Inner">
                                        {DefaultSortedStores.map((item, index) => (
                                        <div key={index} className="M_SA_StoreCard_Outer_Wrap">
                                            <div className="M_SA_StoreCard_Wrap">
                                                <div className="M_SA_StoreCard_Col1">
                                                    <div>
                                                        <img src={item.companyLogo} width="100%" height="100%" alt='CompanyLogo'/>
                                                    </div>    
                                                </div>
                                                <div className="M_SA_StoreCard_Col2">
                                                    <div className="M_SA_StoreCard_About">
                                                        <h2>Store: {item.storeName}</h2>
                                                    </div>
                                                    <div className="M_SA_StoreCard_Desc">
                                                        <p>{item.aboutStore}</p>
                                                    </div>            
                                                </div>
                                                <div className="M_SA_StoreCard_Col3">
                                                    <div className="M_SA_StoreCard_Address">
                                                        <p><strong>Address:</strong> {item.deliveryAddress}</p>
                                                    </div>
                                                    <div className="M_SA_StoreStatus">
                                                        <p><strong>Status:</strong> {item.statusDescription}</p>
                                                    </div>    
                                                    <div className="M_SA_StoreCard_Btn">
                                                        { item.isApproved == 1 || item.isApproved == 3 ?
                                                        <div>
                                                            <button className="M_SA_OpenFeatured BtnGrey" onClick={() => {dispatch(SelectedStore(item)); MApproveStoreIsOpen(true); }}>
                                                                Approve Store
                                                            </button>
                                                            {isOpenApproveStore && <ApproveStoreModel MApproveStoreIsOpen={MApproveStoreIsOpen}/>}
                                                            <button className="M_SA_OpenFeatured BtnGrey" onClick={() => {dispatch(SelectedStore(item)); MRejectStoreIsOpen(true);}}>
                                                                Reject Store
                                                            </button>
                                                            {isOpenRejectStore && <RejectStoreModel MRejectStoreIsOpen={MRejectStoreIsOpen}/>}
                                                        </div>
                                                        :
                                                        <div>
                                                            <button className="M_SA_OpenFeatured BtnGrey" onClick={() => {dispatch(SelectedStore(item)); MRejectStoreIsOpen(true);}}>
                                                                Reject Store
                                                            </button>
                                                            {isOpenRejectStore && <RejectStoreModel MRejectStoreIsOpen={MRejectStoreIsOpen}/>}
                                                        </div>    
                                                        }
                                                        <div className="M_SA_StoreCard_Inner">
                                                            <div>
                                                                <button className="BtnGrey" onClick={()=>{
                                                                    dispatch(CurrentProduct(item)); navigate("/UserStore");}}>View Store</button>
                                                            </div> 
                                                            <div>
                                                                <button className="BtnGrey" onClick={()=>{
                                                                    dispatch(CurrentProduct(item)); navigate("/CreateProduct");}}>Create Product</button>
                                                            </div>
                                                        </div>     
                                                    </div>            
                                                </div>
                                            </div>
                                        </div>
                                        ))}
                                    </div> 
                                }
                            </div>        
                            :
                            <div className="M_SA_Stores_Inner">        
                                {FilteredStores.map((item, index) => (
                                <div key={index} className="M_SA_StoreCard_Outer_Wrap">
                                    <div className="M_SA_StoreCard_Wrap">
                                        <div className="M_SA_StoreCard_Col1">
                                            <div>
                                                <img src={item.companyLogo} width="100%" height="100%" alt='CompanyLogo'/>
                                            </div>      
                                        </div>
                                        <div className="M_SA_StoreCard_Col2">
                                            <div className="M_SA_StoreCard_About">
                                                <h2>Store: {item.storeName}</h2>
                                            </div>
                                            <div className="M_SA_StoreCard_Desc">
                                                <p>{item.aboutStore}</p>
                                            </div>            
                                        </div>
                                        <div className="M_SA_StoreCard_Col3">
                                            <div className="M_SA_StoreCard_Address">
                                                <p><strong>Address:</strong> {item.deliveryAddress}</p>
                                            </div>
                                            <div className="M_SA_StoreStatus">
                                                <p><strong>Status:</strong> {item.statusDescription}</p>
                                            </div> 
                                            <div className="M_SA_StoreCard_Btn">
                                                { item.isApproved == 1 || item.isApproved == 3 ?
                                                    <div>
                                                        <button className="M_SA_OpenFeatured BtnGrey" onClick={() => {dispatch(SelectedStore(item)); MApproveStoreIsOpen(true);}}>
                                                            Approve Store
                                                        </button>
                                                        {isOpenApproveStore && <ApproveStoreModel MApproveStoreIsOpen={MApproveStoreIsOpen}/>}
                                                        <button className="M_SA_OpenFeatured BtnGrey" onClick={() => {dispatch(SelectedStore(item)); MRejectStoreIsOpen(true);}}>
                                                            Reject Store
                                                        </button>
                                                        {isOpenRejectStore && <RejectStoreModel MRejectStoreIsOpen={MRejectStoreIsOpen}/>}
                                                    </div>
                                                    :
                                                    <div>
                                                        <button className="M_SA_OpenFeatured BtnGrey" onClick={() => {dispatch(SelectedStore(item)); MRejectStoreIsOpen(true);}}>
                                                            Reject Store
                                                        </button>
                                                        {isOpenRejectStore && <RejectStoreModel MRejectStoreIsOpen={MRejectStoreIsOpen}/>}
                                                    </div>                  
                                                }
                                                <div className="M_SA_StoreCard_Inner">
                                                    <div>
                                                        <button className="BtnGrey" onClick={()=>{
                                                            dispatch(CurrentProduct(item)); navigate("/UserStore");}}>View Store</button>
                                                    </div> 
                                                    <div>
                                                        <button className="BtnGrey" onClick={()=>{
                                                            dispatch(CurrentProduct(item)); navigate("/CreateProduct");}}>Create Product</button>
                                                    </div>
                                                </div>             
                                            </div>            
                                        </div>
                                    </div>
                                </div>
                                ))}
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Store_Approvals;