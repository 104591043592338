import AboutUs from '../Components/Documentation/AboutUs';

function AboutUsPage() {
    return(
        <div>
            <AboutUs></AboutUs>
        </div>
    )
}

export default AboutUsPage;