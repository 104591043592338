import './Accounts.scss';
import {React,useState, useEffect,useRef, startTransition} from "react";
import { RiCloseLine } from "react-icons/ri";
import Api from '../../AxiosRest/AxiosRest';
import { useNavigate } from 'react-router-dom';
import "rc-tooltip/assets/bootstrap.css";
import Tooltip from "rc-tooltip";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Swal from 'sweetalert2';

//State imports
import { useSelector, useDispatch } from 'react-redux';
import { Login, CurrentUser } from '../../Actions';

function EditProduct(){

    const MAX_COUNT = 5;//max number of images that can be uploaded

    //Declarations
    let navigate = useNavigate();

    let IndustrySector;

    const [ProductName, setProductName] = useState("");
    const [ProductDesc, setProductDesc] = useState("");
    const [Quantity, setQuantity] = useState("");
    const [ProductImages, setProductImages] = useState([]);
    const [Price, setPrice] = useState("");
    const [DealPrice, setDealPrice] = useState("");
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    let [IndustrySec ,setIndustrySec] = useState([]);
    let [currentIndustrySec, setCurrentIndustrySec] = useState([]);
    const [fileLimit, setFileLimit] = useState(false);
    const [NewImages, setNewImages] = useState([]);
    let [defaultIndustrySec, setDefaultIndustrySec] = useState([]);

    let UserDetails = useSelector(state => state.CurrentUser);
    let SelectedProduct = useSelector(state => state.CurrentProduct);
    let newProduct = new FormData();
    

    let Validate = () => {
        if(UserDetails == " " || SelectedProduct == " "){
            navigate("/");
        }
    }

    //Firing API call to recieve full list of Industry Sectors
    let GetIndustrySec = async() =>{
        try{
            await Api.get('/store/Products/GetIndustrySectors').then((response) => {
                setIndustrySec(response.data.industrySectors);

                let Default = [];
                Default = response.data.industrySectors;
                for(let item of Default){
                    if(item.industrySectorID == SelectedProduct.industrySectorID){
                        setDefaultIndustrySec(item.industrySectorName);
                    }
                }
            });
        }
        catch(exception){   
            
        }  
    }

    


    function updateProduct(){
        startDate.setHours(0);
        startDate.setMinutes(1);
        endDate.setMinutes(59);
        endDate.setHours(23);

        if(startDate.getTime() <= endDate.getTime()){
            newProduct.append("productName", ProductName)
            newProduct.append("userID", UserDetails.id)
            newProduct.append("catalogID", UserDetails.catalogID)
            newProduct.append("productID", SelectedProduct.productID)
            newProduct.append("productDescription", ProductDesc)
            newProduct.append("quantity", Quantity)
            newProduct.append("industrySectorID", currentIndustrySec)      
            newProduct.append("startDate", startDate.toISOString())
            newProduct.append("endDate", endDate.toISOString())
            newProduct.append("price", Price)
            if(DealPrice != ""){
                newProduct.append("comparePrice", DealPrice)
            }
            else{
                newProduct.append("comparePrice", 0);
            }
            
            if(NewImages.length > 0){
                //Building Form Data object to send to API
                for (const image of NewImages){
                    newProduct.append("Images", image);
                }
                newProduct.append("originalImages", ProductImages)
                newProduct.append("picturesUpdate", true)
            }
            else{
                var file = new File([""], ProductImages)
                newProduct.append("images", file)
                newProduct.append("OriginalImages", ProductImages)
                newProduct.append("picturesUpdate", false)
            }
    
            if(newProduct != null){
                Swal.fire({
                    title: 'Loading',
                    html: '<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>',
                    showCancelButton: false,
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    didOpen: () => {
                      Swal.showLoading();
                    }
                });
                Api.post('/store/Products/UpdateProduct', newProduct, {headers: {
                    'Content-Type': 'multipart/form-data'
                }
                })
                .then((response) => {
                    Swal.close();
                    Swal.fire({
                        title: 'Success',
                        text: 'Your product has successfully been updated',
                        icon: 'success',
                        showConfirmButton: false
                    })
                    setTimeout(() => {
                        navigate("/Account");
                    }, 1500);
                    
                })
                .catch((response) => {
                    Swal.close();
                    Swal.fire(
                    'Oops',
                    'We couldnt do that right now',
                    'error'
                    )
                })
                
            }
        }
        else{
            Swal.close();
            Swal.fire(
                'Error',
                'Please ensure The start date is before the end date if you intend to create a promotion',
                'error'
            )
        }
    }

    //Handling the changing of multiple input files and sorting them into an array that I will later filter through and send to the API
    const handleUploadFiles = files => {
        const uploaded = [...NewImages];
        let limitExceeded = false;
        files.some((file) => {
            if(uploaded.findIndex((f) => f.name === file.name) === -1){
                uploaded.push(file);
                if(uploaded.length === MAX_COUNT) setFileLimit(true);
                if(uploaded.length > MAX_COUNT){
                    alert('You can only add a maximum of 5 files');
                    setFileLimit(false);
                    limitExceeded = true;
                    return true;
                }
            }
        })
        if (!limitExceeded) setNewImages(uploaded)
    }

    const handleFileEvent = (e) => {
        const chosenFiles = Array.prototype.slice.call(e.target.files)
        handleUploadFiles(chosenFiles);
    }

    function ValidateDeal(){
        
        if(DealPrice >= Price){
            
            Swal.fire(
                'error',
                'Please ensure the Deal Price is less than the Base Price',
                'warning'
            )
            document.getElementById("Edit_Prod_UpdateBtn").disabled = true;
        }
        else{
            document.getElementById("Edit_Prod_UpdateBtn").disabled = false;
        }
    }

    function setDefaultValues(){
        setProductName(SelectedProduct.productName);
        setProductDesc(SelectedProduct.productDescription);
        setQuantity(SelectedProduct.quantity);
        setCurrentIndustrySec(SelectedProduct.industrySectorID);
        setProductImages(SelectedProduct.imagesUrls);
        setPrice(SelectedProduct.productPrice);
        setDealPrice(SelectedProduct.comparePrice);
        setStartDate(new Date(Date.parse(SelectedProduct.startDate)));
        setEndDate(new Date(Date.parse(SelectedProduct.endDate)));
    }

    function ScrollToTop(){
        window.scrollTo({
          top: 0,
          behavior: 'instant',
        })
    }

    useEffect(() => {
        ScrollToTop();
        Validate();
        setDefaultValues();
        GetIndustrySec();
    }, []);


    return(
        <div id="Edit">
            <div>
                <div className="Edit_Wrap">
                    <div>
                        <label for="Edit_ProdName" className="lblEdit_ProdName">Name:</label> 
                        <input id="Edit_ProdName" name="Edit_ProdName" className="Edit_ProdName" type="text" defaultValue={SelectedProduct.productName} onChange={(event) => {
                        setProductName(event.target.value);
                        }} placeholder="Name" required />
                    </div>
                    <div>
                        <label for="ProductDesc" className="lblEdit_ProductDesc">Description:</label> 
                        <textarea id="Edit_ProductDesc" name="Edit_ProductDesc" className="Edit_ProductDesc" rows="4" cols="50" type="text" defaultValue={SelectedProduct.productDescription} onChange={(event) => {
                        setProductDesc(event.target.value)
                        }} placeholder="Description" required />
                    </div>
                    <div>
                        <label for="Edit_Quantity" className="lblEdit_Quantity">Quantity:</label> 
                        <input id="Edit_Quantity" name="Edit_Quantity" className="Edit_Quantity" type="text" defaultValue={SelectedProduct.quantity} onChange={(event) => {
                        setQuantity(event.target.value)
                        }} placeholder="Description" required />
                    </div>
                    <div>
                        <label for="Edit_IndustrySec">Industry Sector:</label>
                        <select name="Edit_IndustrySec" id="Edit_IndustrySec" onChange={(e)=> {setCurrentIndustrySec(e.target.value)}}> 
                            { SelectedProduct.industrySectorID == "" ?
                                <option value="0" selected>Industry Sectors:</option>
                                :
                                <option value={SelectedProduct.industrySectorID} selected>{defaultIndustrySec}</option>
                            }
                            {IndustrySec.map((item, index) => (
                                <option value={item.industrySectorID}>
                                    {item.industrySectorName}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="Edit_Images_Wrap">
                        <label for="Edit_Images">New Images:</label>
                        <input type="file" name="ProdTitle" id="ProdTitle" onChange={handleFileEvent} multiple accept="image/png, image/gif, image/jpeg" disabled={fileLimit}></input>
                    </div>
                    <div className="Edit_Prices_Wrap">
                        <div>
                            <Tooltip placement="top" overlay={<span>This is the base price of your product</span>}>
                                <label for="Edit_Price" className="lblEdit_Price">Price:</label> 
                            </Tooltip>
                            <input id="Edit_Price" name="Edit_Price" className="Edit_Price" type="number" defaultValue={SelectedProduct.productPrice} onChange={(event) => {
                            setPrice(event.target.value)
                            }} placeholder="Price" required />
                        </div>
                        <div>
                            <Tooltip placement="top" overlay={<span>This is the promotional price. Setting this value will cause your product to appear on sale</span>}>
                                <label for="Edit_DealPrice" className="lblEdit_DealPrice">Deal Price:</label>
                            </Tooltip>     
                            <input id="Edit_DealPrice" name="Edit_DealPrice" className="Edit_DealPrice" type="number" onBlur={(event) => {ValidateDeal()}} defaultValue={SelectedProduct.comparePrice} onChange={(event) => {
                            setDealPrice(event.target.value)
                            }} placeholder="Deal Price" required />
                        </div>
                    </div>
                    <div className="Edit_PromoDates_Wrap">
                        <div>
                            <Tooltip placement="left" overlay={<span>Starting date of this products promotion, if applicable.</span>}>
                                <label for="Create_StartDate" className="Create_StartDate">Start Date:</label> 
                            </Tooltip>
                            <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                        </div>
                        <div>
                            <Tooltip placement="left" overlay={<span>Ending date of this products promotion, if applicable.</span>}>
                                <label for="Create_EndDate" className="lblCreate_EndDate">End Date:</label> 
                            </Tooltip>
                            <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
                        </div>
                    </div>
                </div>
                <div className="Update_Btn_Wrap">
                    <button id="Edit_Prod_UpdateBtn" className="BtnGrey" onClick={() => {updateProduct()}}>Update Product</button>
                    <button className="BtnGrey" onClick={() => navigate("/Account")}>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
}

export default EditProduct;