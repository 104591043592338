import '../Manager/Manager.scss';
import Api from '../../AxiosRest/AxiosRest';
import { useNavigate, useLocation } from 'react-router-dom';
import {React,useState, useEffect} from "react";
import Sidebar from './SideBar';
import Approval from './Store_Approvals';
import Stats from './Store_Stats';
import Featured from './FeaturedSupplier';
import ManageUsers from './ManageUser';
import SearchStats from './SearchStats';

//State imports
import { useSelector, useDispatch } from 'react-redux';
import { AllStores, CurrentProduct, CurrentUser } from '../../Actions';

function ManagerHome(){

    const location = useLocation();
    let dispatch = useDispatch();
    let navigate = useNavigate();
    let LoggedInUser = "";

    let MenuItem = useSelector(state => state.SelectedMenu);

    //Firing API call to recieve full list of available stores
    let GetStores = async() =>{
        try{
            await Api.get('/store/Stores/GetAllStores').then((response) => {
                dispatch(AllStores(response.data));
            });
        }
        catch(exception){   
            
        }  
    }

    function RenderNav(){
        if(location.pathname === "/ManagerHome"){
            let Nav_Wrap = document.getElementById("Main_Nav_Menu");
            Nav_Wrap.style.display = "none";
        }
    }

    function RenderCSSFixes(){
        let bm_Menu = document.getElementsByClassName("bm-menu");
        bm_Menu[0].setAttribute("style", "overflow:none; height: 100%; box-sizing: border-box;");
        let bm_item = document.getElementsByClassName("bm-item");
        bm_item[1].setAttribute("style", "display: flex; flex-direction: column;");
        bm_item[0].setAttribute("style", "display: flex; flex-direction: column;");
    }

    let Validate = () => {
        LoggedInUser = JSON.parse(window.sessionStorage.getItem('LoggedInUser')); 
        dispatch(CurrentUser(LoggedInUser));
        if(LoggedInUser == null){
            navigate("/");
        }
    }

    function ScrollToTop(){
        window.scrollTo({
          top: 0,
          behavior: 'instant',
        })
    }

    

    function RenderSwitch(){
        switch(MenuItem) {
            case 'Stats':
              return <Stats></Stats>;
            case 'Approved':
              return <Approval></Approval>;
            case 'Featured':
              return <Featured></Featured>;
            case 'User':
              return <ManageUsers></ManageUsers>;
            case 'SearchStats':
              return <SearchStats></SearchStats>;                 
            default:
              return <Stats></Stats>;
        }
    }

    useEffect(() => {
        ScrollToTop();
        Validate()
        RenderNav();
        RenderCSSFixes();
        GetStores();
    }, []);

    return(
        <div className="ManagerHome" id="Manager_Home_Container">
            <Sidebar pageWrapId={'Manager_Wrap'} outerContainerId={'Manager_Home_Container'}/>
            <div id="Manager_Wrap">
                {   
                  RenderSwitch()
                }          
            </div>
        </div>
    )
}

export default ManagerHome;