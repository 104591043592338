import ProductInfo from '../Product/Components/ProductInfo';
import SimilarProducts from './Components/SimilarProducts';

function ProductTemplate() {
    return(
        <div>
            <ProductInfo></ProductInfo>
        </div>
    );
}

export default ProductTemplate;