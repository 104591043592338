import './Accounts.scss';
import {React,useState, useEffect} from "react";
import Api from '../../AxiosRest/AxiosRest';
import { useNavigate } from 'react-router-dom';

//State imports
import { useSelector, useDispatch } from 'react-redux';
import { Login, CurrentUser } from '../../Actions';
import Swal from 'sweetalert2';

function EditDetails(){

    const MAX_COUNT = 1;

    const [username, setUsername] = useState("");
    const [NewPassword, setNewPassword] = useState("");
    const [NewPasswordConfirm, setNewPasswordConfirm] = useState("");
    const [firstname, setFirstname] = useState("");
    const [surname, setSurname] = useState("");
    const [contactNum, setContactNum] = useState("");
    const [deliveryAddress, setDeliveryAddress] = useState("");
    const [companyLogo, setCompanyLogo] = useState([]);
    const [storeName, setStoreName] = useState("");
    const [email, setEmail] = useState("");
    const [TCs, setTCs] = useState("");
    const [AboutStore, setAboutStore] = useState("");
    const [LogoUpdated, setLogoUpdated] = useState(false);
    const [ExistingBlob, setExistingBlob] = useState("");
    const [ExistingBlobArr, setExistingBlobArr] = useState("");//this is used solely to account for the fact that the vairable needs to be an array if we want to send new data
    const [fileLimit, setFileLimit] = useState(false);


    let navigate = useNavigate();
    let dispatch = useDispatch();
    let UserDetails = useSelector(state => state.CurrentUser);
    let EditedUser = new FormData();
    let UsernameValidated;

    //Validation to ensure the data has been sent that is needed to perform further actions on the page
    let Validate = () => {
        if(UserDetails == " "){
            navigate("/");
        }
    }

    function ValidateUserName(){
        if(username == UserDetails.username){//this method is used to ensure that the username is unique and passed validation
            UsernameValidated = true;
        }else{
            Api.post('/store/StoreUser/ValidateUsername',  {username: username})//send the username to the api to pass unique validation
            .then((response) => {
                UsernameValidated = response.data;

                //this will fire if the username has not passed the unique value condition
                if(UsernameValidated === false){
                Swal.fire(
                    'Oops!',
                    'Please ensure your username is unique :)',
                    'error'
                )
                }
            });
        }
    }

    //creating the object that I will send to the api for the new updatetd details
    function CreateUserObject(){
        ValidateUserName();
        EditedUser.append("catalogID", UserDetails.catalogID);
        EditedUser.append("id", UserDetails.id);
        if(username == ""){
            Swal.fire(
                'Oops!',
                'Please ensure you have entered a username)',
                'error'
            )
            return;   
        }else{
            EditedUser.append("username", username);
        }
        if(storeName == ""){
            Swal.fire(
                'Oops!',
                'Please ensure you have entered a Store Name)',
                'error'
            )
            return;
        }else{
            EditedUser.append("storeName", storeName);
        }
        if(firstname == ""){
            Swal.fire(
                'Oops!',
                'Please ensure you have entered a Firstname)',
                'error'
            )
            return;
        }else{
            EditedUser.append("firstName", firstname);
        }
        if(surname == ""){
            Swal.fire(
                'Oops!',
                'Please ensure you have entered a Surname)',
                'error'
            )
            return;
        }else{
            EditedUser.append("surname", surname);
        }
        if(contactNum == ""){
            Swal.fire(
                'Oops!',
                'Please ensure you have entered a Contact Number)',
                'error'
            )
            return;
        }else{
            if(validatePhone(contactNum)) {
                EditedUser.append("contactNumber", contactNum);
              } else {
                Swal.fire(
                  'Woops',
                  'Please provide a Valid Phone Number',
                  'warning'
                )
                return;
            }
            
        }
        if(deliveryAddress == ""){
            EditedUser.append("deliveryAddress", "N/A");
        }
        else{
            EditedUser.append("deliveryAddress", deliveryAddress);
        }
        if(TCs == ""){
            EditedUser.append("tandC", "N/A");
        }
        else{
            EditedUser.append("tandC", TCs);
        }
        if(email == ""){
            Swal.fire(
                'Oops!',
                'Please ensure you have entered a Email Address)',
                'error'
            )
            return;
        }else{
            if(validateEmail(email)) {
                EditedUser.append("email", email);
              } else {
                Swal.fire(
                  'Woops',
                  'Please provide a Valid Email Address',
                  'warning'
                )
                return;
            }
            
        }
        if(AboutStore == ""){
            EditedUser.append("aboutStore", "N/A");
        }
        else{
            EditedUser.append("aboutStore", AboutStore);
        }
        EditedUser.append("authorizedToken", UserDetails.authorizedToken)
        if(companyLogo == ""){
            var file = new File([""], ExistingBlob)
            EditedUser.append("existingBlob", ExistingBlob);
            EditedUser.append("companyLogo", file);
        }
        else{
            for (const image of companyLogo){   
                EditedUser.append("companyLogo", image);
            }
            EditedUser.append("existingBlob", ExistingBlob);
        }
        EditedUser.append("logoUpdated", LogoUpdated);

        if(UsernameValidated == true){
            updateUsers(); 
        }else{
            Swal.fire(
                'Woops',
                'Looks like we encountered an error with your Username, please use a different username and try again.',
                'warning'
            )
            return
        }
        
    }

    //RegEx pattern to validate the Phone Number
    function validatePhone(contactNum) {
        let res = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
        return res.test(contactNum);
    }

    //RegEx pattern to validate the email address
    function validateEmail(email) {
        let res = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        return res.test(email);
    }

    //The method to fire the new object to the api for updating
    let updateUsers = async () => {
        Swal.fire({
            title: 'Loading',
            html: '<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>',
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
        });
        
        Api
        .postForm('/store/StoreUser/UpdateUser', 
            EditedUser, {
            headers: {
                'Content-Type': 'multipart/form-data'
                }
            }
        )
        .then((response) => {
            Swal.close();
            Swal.fire(
            'Success!',
            'Details have been successfully updated',
            'success'
            )
            setTimeout(navigate("/Account"), 2000);
        })
        .catch((response) => {
        Swal.close();
        Swal.fire(
            'Error!',
            'There was an error updating your details',
            'error'
        )
        });
        
    }

    function changePassword(){
        Swal.fire({
            title: 'Loading',
            html: '<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>',
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
        });
        if(NewPassword !== "" && NewPasswordConfirm !== ""){
            if(NewPassword !== NewPasswordConfirm){
                let confirms = document.getElementById("Edit_PasswordConfirm");
                confirms.style.border = "1px solid #CC171E";
                Swal.fire(
                    'Woah',
                    'Looks Like your password fields do not match',
                    'warning'
                )
            }
            else{
                let EditedPassword = {userid: UserDetails.id, newpassword: NewPassword};
                document.getElementById("Edit_PasswordConfirm").style.border = "1px solid #333";
                Api
                .post('/store/StoreUser/UpdateUserPassword', 
                    EditedPassword
                )
                .then((response) => {
                    Swal.close();
                    Swal.fire(
                    'Success!',
                    'Details have been successfully updated',
                    'success'
                    )
                    setTimeout(navigate("/Account"), 2000);
                })
                .catch((response) => {
                Swal.close();
                Swal.fire(
                    'Error!',
                    'There was an error updating your password, please try again later',
                    'error'
                )
                });
            }
        }
    }



    //this method will accept an array of images and filter that array to determine if it has exceeded the limit of images allow to submit and send the individual images to the state variable ready to sent to the server
    const handleImageChange = files => {//this method will only fire if the user uploades a new image
        const uploaded = [...companyLogo];
        let limitExceeded = false;
        files.some((file) => {
            if(uploaded.findIndex((f) => f.name === file.name) === -1){
                uploaded.push(file);
                if(uploaded.length === MAX_COUNT) setFileLimit(true);
                if(uploaded.length > MAX_COUNT){
                    alert('You can only add a maximum of 1 file');
                    setFileLimit(false);
                    limitExceeded = true;
                    return true;
                }
            }
        })
        if (!limitExceeded) setCompanyLogo(uploaded)
    }


    //this method will accept each image as it is uploaded and send it to the HandleImageChange method to handle
    const handleLogoFileEvent = (e) => {
        const chosenFiles = Array.prototype.slice.call(e.target.files)
        handleImageChange(chosenFiles);
    }

    function setDefaultValues(){
        setUsername(UserDetails.username);
        setStoreName(UserDetails.storeName);
        setFirstname(UserDetails.firstName);
        setSurname(UserDetails.surname);
        setContactNum(UserDetails.contactNumber);
        setDeliveryAddress(UserDetails.deliveryAddress);
        if(UserDetails.companyLogo != ""){//this is to account for nulls to avoid crashes
            setExistingBlob(UserDetails.companyLogo);
        }
        else{
            setExistingBlob("NA");
        }
        setEmail(UserDetails.email);
        setTCs(UserDetails.tandC);
        setAboutStore(UserDetails.aboutStore);
    }

    

    function ScrollToTop(){
        window.scrollTo({//to ensure the page always loads at the top
          top: 0,
          behavior: 'instant',
        })
    }

    useEffect(() => {
        Validate();
        setDefaultValues();
        ScrollToTop();
    }, []);


    return(
        <div id="Edit">
            <div>
                <div className="Edit_Wrap">
                    <div>
                        <label for="username" className="lblEdit_username">Username:</label> 
                        <input id="Edit_username" name="username" className="Edit_username" type="text" defaultValue={username} onChange={(event) => {
                        setUsername(event.target.value);
                        }} placeholder="username" required />
                    </div>
                    <div>
                        <label for="firstname" className="lblEdit_firstname">First Name:</label> 
                        <input id="Edit_firstname" name="Edit_firstname" className="Edit_firstname" type="text" defaultValue={firstname} onChange={(event) => {
                        setFirstname(event.target.value);
                        }} placeholder="First Name" required />
                    </div>
                    <div>
                        <label for="surname" className="lblEdit_surname">Surname:</label> 
                        <input id="Edit_surname" name="surname" className="Edit_surname" type="email" defaultValue={surname} onChange={(event) => {
                        setSurname(event.target.value);
                        }} placeholder="Surname" required />
                    </div>
                    <div>
                        <label for="contactNum" className="lblEdit_contactNum">Contact Number:</label> 
                        <input id="Edit_contactNum" name="contactNum" className="Edit_contactNum" type="tel" defaultValue={contactNum} onChange={(event) => {
                        setContactNum(event.target.value);
                        }} placeholder="Contact Number" required />
                    </div>
                    <div>
                        <label for="deliveryAddress" className="lblEdit_deliveryAddress">Delivery Address:</label> 
                        <input id="Edit_deliveryAddress" name="deliveryAddress" className="Edit_deliveryAddress" type="tel" defaultValue={deliveryAddress} onChange={(event) => {
                        setDeliveryAddress(event.target.value);
                        }} placeholder="Delivery Address" required />
                    </div>
                    <div>
                        <label for="Edit_StoreName" className="Edit_StoreName">Store Name:</label> 
                        <input id="Edit_StoreName" name="Edit_StoreName" className="Edit_StoreName" type="text" defaultValue={storeName} onChange={(event) => {
                            setStoreName(event.target.value);
                        }} placeholder="Store Name" required="true"/>
                    </div>
                    <div>
                        <label for="Edit_Email" className="Edit_Email_lbl">Email:</label> 
                        <input id="Edit_Email" name="Edit_Email" className="Edit_Email" type="email" defaultValue={email} onChange={(event) => {
                            setEmail(event.target.value); 
                        }} placeholder="Email" required="true"/>
                    </div>
                    <div className="Supplier_About_Wrap">
                        <label for="Supplier_About_Store">About Store: </label>
                        <textarea id="Supplier_About_Store" name="Supplier_About_Store" rows="4" cols="50" defaultValue={AboutStore} onChange={(event) => {
                            setAboutStore(event.target.value);
                        }} required="true"></textarea>
                    </div>
                    <div className="Supplier_TCs_Wrap">
                        <label for="Supplier_TCs">Terms and Conditions: </label>
                        <textarea id="Supplier_TCs" name="Supplier_TCs" rows="4" cols="50" defaultValue={TCs} onChange={(event) => {
                            setTCs(event.target.value);
                        }} required="true"></textarea>
                    </div>
                    <div className="Edit_CompanyLogo_Wrap">
                        <div className="Edit_CompanyLogo_Input">
                            <label for="EditCompanyLogo" className="EditLogo_lbl">Store Logo:</label> 
                            <input type="file" id="EditCompanyLogo" multiple accept="image/png, image/gif, image/jpeg" name="EditCompanyLogo" onChange={(event) => {
                                handleLogoFileEvent(event); setLogoUpdated(true);
                            }}/>
                        </div>
                    </div>
                    <div>
                        <label for="Edit_Password" className="Edit_Password_lbl">New Password:</label> 
                        <input id="Edit_Password" name="Edit_Password" className="Edit_Password" type="password" onChange={(event) => {
                            setNewPassword(event.target.value); 
                        }} placeholder="New Password"/>
                    </div>
                    <div>
                        <label for="Edit_PasswordConfirm" className="Edit_PasswordConfirm_lbl">New Password:</label> 
                        <input id="Edit_PasswordConfirm" name="Edit_PasswordConfirm" className="Edit_PasswordConfirm" type="password" onChange={(event) => {
                            setNewPasswordConfirm(event.target.value); 
                        }} placeholder="Confirm New Password"/>
                    </div>
                </div>
                <div className="Update_Btn_Wrap">
                    <button className="BtnGrey" onClick={() => { CreateUserObject() }}>Update Details</button>
                    <button className="BtnGrey" onClick={()=> { changePassword() }}>Change Password</button>
                    <button className="BtnGrey" onClick={() => navigate("/Account")}>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
}

export default EditDetails;