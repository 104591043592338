import '../Login/Login.scss';
import {React,useState, useEffect} from "react";
import { RiCloseLine } from "react-icons/ri";
import Api from '../../../AxiosRest/AxiosRest';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Tooltip from "rc-tooltip";



const Registration = ({ OpenRegister }) => {

    const MAX_COUNT = 1;
  
    const [Password, setPassword] = useState('');
    const [TCs, setTCs] = useState('');
    const [checked, setChecked] = useState(false);
    const [user, setUser] = useState([]);
    const [FirstName, setFirstName] = useState("");
    const [Surname, setSurname] = useState("");
    const [UserName, setUserName] = useState("");
    const [ContactNumber, setContactNumber] = useState("");
    const [DeliveryAddress, setDeliveryAddress] = useState("");
    const [Email, setEmail] = useState("");
    const [AboutStore, setAboutStore] = useState("");
    const [StoreName, setStoreName] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [companyLogo, setCompanyLogo] = useState("");
    const [SMMEID, setSMMEID] = useState("");
    const [OTP, setOTP] = useState("");
    const [ExistingSupplier, setExistingSupplier] = useState("");
    const [fileLimit, setFileLimit] = useState(false);
    const [ExistPassedCheck, setExistPassedCheck] = useState(false);
    let navigate = useNavigate();
    let NewUser = new FormData();
    let UsernameValidate;
    const [Termschecked, setTermsChecked] = useState(false);


    const handleChange = () => {
      setTermsChecked(!Termschecked);
    };

    
    const Checkbox = ({ label, value, onChange, styling}) => {
      return (
        <div className={styling} style={{paddingTop:'2%'}}>
        <label className="SCNetTerms_Chk" for="SCNetTerms">I agree to the <a href="https://scnetfilestorage.blob.core.windows.net/storeassets/230630 Buyer Terms.docx" target="_blank" rel="noreferrer">Buyer</a> and <a href="https://scnetfilestorage.blob.core.windows.net/storeassets/230619 Seller Terms.docx" target="_blank" rel="noreferrer">Seller</a> terms and conditions</label>
        <input type="checkbox" id="SCNetTerms" name="SCNetTerms"checked={value} onChange={onChange}></input>
        </div>
      );
    };
    let defaultProfileImg = "C:\Users\User\Desktop\React Projects\SCN_Store\src\Images\defaultProfile.jpg";
    

    //creation of the object that is sent to the the create user method and then sent to the api
    let CreateUserObject = () => {
      if(UserName == ""){
        Swal.fire(
          'Woops',
          'We require a unique username',
          'warning'
        )
        return;
      }else{
        NewUser.append("username", UserName);
      }
      if(FirstName == ""){
        Swal.fire(
          'Woops',
          'Please provide a firstname',
          'warning'
        )
        return;
      }else{
        NewUser.append("FirstName", FirstName);
      }
      if(Surname == ""){
        Swal.fire(
          'Woops',
          'Please provide a surname',
          'warning'
        )
        return;
      }else{
        NewUser.append("Surname", Surname);
      }
      if(Password == ""){
        Swal.fire(
          'Woops',
          'Please provide a password to secure your account',
          'warning'
        )
        return;
      }else{
        NewUser.append("Password", Password);
      }
      NewUser.append("DeliveryAddress", DeliveryAddress);
      if(ContactNumber == ""){
        Swal.fire(
          'Woops',
          'Please provide a Contact Number',
          'warning'
        )
        return;
      }else{
        if(validatePhone(ContactNumber)) {
          NewUser.append("contactNumber", ContactNumber);
        } else {
          Swal.fire(
            'Woops',
            'Please provide a Valid Phone Number',
            'warning'
          )
          return;
        }
        
      }
      if(StoreName == ""){
        Swal.fire(
          'Woops',
          'Please provide a Store Name',
          'warning'
        )
        return;
      }else{
        NewUser.append("StoreName", StoreName);
      }
      if(SMMEID == "")
      {
        NewUser.append("SMMEID", 0);
      }
      else{
        NewUser.append("SMMEID", SMMEID);
      }
      
      if(TCs == ""){
        NewUser.append("TandC", "NA");
      }
      else{
        NewUser.append("TandC", TCs);
      }
      if(AboutStore == ""){
        NewUser.append("AboutStore", "NA");
      }
      else{
        NewUser.append("AboutStore", AboutStore);
      }
      if(Email == ""){
        Swal.fire(
          'Woops',
          'Please provide a Email Address',
          'warning'
        )
        return;
      }else{
        if(validateEmail(Email)) {
          NewUser.append("Email", Email);
        } else {
          Swal.fire(
            'Woops',
            'Please provide a Valid Email Address',
            'warning'
          )
          return;
        }
      } 
      NewUser.append("authorizedToken", "Wadu");
      NewUser.append("Id", 0);
      if(companyLogo == ""){
        Swal.fire(
          'Woops',
          'We need a company logo to show everyone',
          'warning'
        )
      }else{
        for (const image of companyLogo){//stop the user if their company logo is not uploaded
          NewUser.append("companyLogo", image);
        }

        let checkbox = document.getElementById("SCNetTerms");

        if(Termschecked == true){
          NewUser.append("TermsAccepted", 1);
          if(Password !== null){//allow the creation of the user to continue
            CreateUser();
          }
        }
        else{
          Swal.fire(
            'Woops',
            'We need you to accept our Ts and Cs',
            'warning'
          )
        }
        
      }
    };

    //RegEx pattern to validate the Phone Number
    function validatePhone(contactNum) {
      let res = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
      return res.test(contactNum);
    }

    //RegEx pattern to validate the email address
    function validateEmail(Email) {
      let res = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,9}$/;
      return res.test(Email);
    }


    //the main method that creates the user in the database based on the inofrmation collected in the process
    let CreateUser = async () => {
      if(Password === confirmPassword){//validating passed confirm passsword condition
        Swal.fire({
          title: 'Loading',
          html: '<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>',
          showCancelButton: false,
          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          }
        });
        Api.post('/store/StoreUser/ValidateUsername',  {username: UserName})//send the username to the api to pass unique validation
           .then((response) => {
            UsernameValidate = response.data;
            if(UsernameValidate === true){
              Api.postForm('/store/StoreUser/Register',  NewUser, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                  }
                  })
                .then((response) => {
                  //this is sweet alerts and is used throughout the site for all alerts 
                  Swal.close();
                  Swal.fire(
                    'Horay!',
                    'You have successfully created an account!',
                    'success'
                  )
                  OpenRegister(false);
              })
              .catch((response) => {
                Swal.close();
                Swal.fire(
                  'Oops!',
                  'Something went wrong there!',
                  'error'
                )
              })
            }   
        })
        .catch((response => {
          Swal.close();
          Swal.fire(
            'Oops!',
            'Please ensure your username is unique :)',
            'error'
          )
        })
        )
      }    
    }


    //this method will accept an array of images and filter that array to determine if it has exceeded the limit of images allow to submit
    const handleImageChange = files => {
      const uploaded = [...companyLogo];
      let limitExceeded = false;
      files.some((file) => {
          if(uploaded.findIndex((f) => f.name === file.name) === -1){
              uploaded.push(file);
              if(uploaded.length === MAX_COUNT) setFileLimit(true);
              if(uploaded.length > MAX_COUNT){
                  Swal.fire(
                    'Error',
                    'You can only upload a maximum of one file',
                    'Error'
                  )
                  setFileLimit(false);
                  limitExceeded = true;
                  return true;
              }
          }
      })
      if (!limitExceeded) setCompanyLogo(uploaded)
    }


    //this method will accept each image as it is uploaded and send it to the HandleImageChange method to handle
    const handleLogoFileEvent = (e) => {
      const chosenFiles = Array.prototype.slice.call(e.target.files)
      handleImageChange(chosenFiles);
    }

    //need to validate the password on the input field to inform the user that their password does not match their confirm password field
    function ValidatePassword(){
      if(Password !== confirmPassword){
        let confirms = document.getElementsByClassName("ConfirmPassword");
        for (var i = 0; i < confirms.length; i++) {
          confirms.item(i).style.border = "1px solid #CC171E";  
        }
        Swal.fire(
          'Woah',
          'Looks Like your password fields do not match',
          'warning'
        )
      }
      else{
        document.getElementById("ConfirmPassword").style.border = "1px solid #333";
      }
    }

    //function will fire when we need to validate that the existing suppliers information is correct
    function GetExisitingSupplier(){
      Swal.fire({
        title: 'Loading',
        html: '<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>',
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });
      Api.post('/store/StoreUser/SendOTP', {
        SMMEID: SMMEID,
        username: UserName
      })
      .then((response) => {
        Swal.close();
        Swal.fire(
          'Great!',
          'A One Time Pin has been sent to the email account associated with your SCNet profile, this will expire in 3 minutes',
          'success'
        )
        setExistPassedCheck(true);
      })
      .catch((response) => {
        Swal.close();
        setExistPassedCheck(false);
        Swal.fire(
          'Error',
          'Something went wrong with that, please ensure your information is correct',
          'error'
        )
      })
    }


    //function that sends the collected OTP to the API for validation that fetches the information about the SCNet users
    function ConfirmOTP(){
      Swal.fire({
        title: 'Loading',
        html: '<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>',
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });
      Api.post('/store/StoreUser/ValidateOTP', {
        SMMEID: SMMEID,
        OTP: OTP,
        username: UserName
      })
      .then((response) => {
        Swal.close();
        setExistingSupplier(response.data);
        setUserName(response.data.username);
        setEmail(response.data.email);
        setContactNumber(response.data.contactNumber);
        setDeliveryAddress(response.data.deliveryAddress);
        setFirstName(response.data.firstName);
        setSurname(response.data.surname);
        setStoreName(response.data.storeName);
        setTCs(response.data.tandC);
        setAboutStore(response.data.aboutStore);
        DisplaySignUpBtn();
      })
      .catch((response) => {
        Swal.close();
        Swal.fire(
          'Error',
          'Oops! There was an error fetching your details',
          'error'
        )
      })
    }


    //this simply determines which type of registration is selected and sets the state variable for logic later
    const HandleCheck = (event) => {
      setChecked(event.target.value);
    }

    useEffect(() => {
      if(user.isLoggedIn){
      NavigateUser();
      }
    });

    function NavigateUser () {
      navigate('/Account');
    }

    function DisplaySignUpBtn(){
      let SignBtn = document.getElementById("RegSignUpBtn");
      SignBtn.style.visibility = "visible";
    }

    function HideSignUpBtn(){
      let SignBtn = document.getElementById("RegSignUpBtn");
      SignBtn.style.visibility = "hidden";
    }
  
    return (
      <>
        <div className="darkBG" onClick={() => OpenRegister(false)} />
        <div id="Register" className="RegisterCentered">
          <div className="Modal">
            <div className="modalHeader">
              <h3 className="heading">REGISTER</h3>
            </div>
            <button className="closeBtn" onClick={() => OpenRegister(false)}>
              <RiCloseLine style={{ marginBottom: "-3px" }} />
            </button>
            <div className="RegModalContent">
                <div>
                    <div className="Register_Type_Check">
                        <div>
                          <label for="SupplierCheck">Existing SCNet Supplier</label>
                          <input type="checkbox" value="ExistingSupplier" id="ExistSupplierCheck" checked={checked === "ExistingSupplier"} name="SupplierCheck" onChange={HandleCheck} onClick={()=>{HideSignUpBtn()}}></input>
                        </div>
                        <div>
                          <label for="SupplierCheck">I am a Supplier</label>
                          <input type="checkbox" value="Supplier" id="SupplierCheck" checked={checked === "Supplier"} name="SupplierCheck" onChange={HandleCheck} onClick={()=>{DisplaySignUpBtn()}}></input>
                        </div>
                        <div>
                          <label for="SupplierCheck">I am an Individual / Student</label>
                          <input type="checkbox" value="Individual" id="IndividualCheck" checked={checked === "Individual"} name="SupplierCheck" onChange={HandleCheck} onClick={()=>{DisplaySignUpBtn()}}></input>
                        </div>
                    </div>
                    <div className="ExistSupplier_Reg_Wrap" aria-hidden={checked !== "ExistingSupplier" ? true : false}>
                      {ExistPassedCheck == false ?
                        <div>
                          <div className="ExistSupplier_Reg_upper">
                            <div className="ExistSupplier_Wrap2">
                              <div className="Reg_Item">
                                  <label for="SMMEID" className="SMMEID"><a>*</a>SMMEID:</label> 
                                  <input id="RegisterSMMEID" name="SMMEID" className="SMMEID" type="text" value={SMMEID} onChange={(event) => {
                                    setSMMEID(event.target.value);
                                  }} placeholder="SMMEID" required="true" />
                              </div>
                              <div className="Reg_Item">
                                  <Tooltip placement="top" overlay={<span>Please use your existing SCNet username</span>}>
                                    <label for="Username" className="Username"><a>*</a>Username:</label>
                                  </Tooltip> 
                                  <input id="RegisterUsername" name="Username" className="Username" type="text" value={UserName} onChange={(event) => {
                                    setUserName(event.target.value);
                                  }} placeholder="Current SCNet Username" required="true" />
                              </div>
                            </div> 
                            <button className="BtnGrey Reg_Fetch_Details" onClick={(event) => { GetExisitingSupplier() }}
                              >Fetch Details</button>               
                          </div>
                        </div> 
                        :
                        <div>
                          {ExistingSupplier == "" ?
                            <div>
                              <div className="ExistSupplier_OTP_Wrap">
                                <div className="ExistSupplier_Upper">
                                  <div>
                                    <h3>Please Enter your OTP!</h3>
                                  </div>
                                  <div className="Reg_Item">
                                    <label for="Reg_OTP" className="Reg_OTP"><a>*</a>OTP:</label> 
                                    <input id="Reg_OTP" name="Reg_OTP" className="Reg_OTP" type="text" value={OTP} onChange={(event) => {
                                      setOTP(event.target.value);
                                    }} placeholder="OTP" required="true"/>
                                  </div>
                                </div>  
                                <div className="ExistSupplier_OTP_Btn_Wrap">
                                  <button className="BtnGrey Reg_Confirm_OTP" onClick={() => {ConfirmOTP()}}>Confirm OTP</button>
                                </div>       
                              </div> 
                            </div>  
                            :
                            <div className="ExistSupplier_Reg_upper">
                              <div className="ExistSupplier_Wrap2">
                                <div className="Reg_Item">
                                  <label for="FirstName" className="FirstName"><a>*</a>First Name:</label> 
                                  <input id="RegisterFirstName" name="FirstName" className="FirstName" type="text" defaultValue={ExistingSupplier.firstName} onChange={(event) => {
                                    setFirstName(event.target.value);
                                  }} placeholder="First Name" required="true"/>
                                </div>
                                <div className="Reg_Item">
                                    <label for="Surname" className="Surname_lbl"><a>*</a>Surname:</label> 
                                    <input id="Surname" name="Surname" className="Surname" type="text" defaultValue={ExistingSupplier.surname} onChange={(event) => {
                                      setSurname(event.target.value);
                                    }} placeholder="Surname" required="true"/>
                                </div>
                                <div className="Reg_Item">
                                    <label for="Username" className="Username"><a>*</a>Username:</label> 
                                    <input id="RegisterUsername" name="Username" className="Username" type="text" defaultValue={ExistingSupplier.username} onChange={(event) => {
                                      setUserName(event.target.value);
                                    }} placeholder="Username" required="true"/>
                                </div>
                                <div className="Reg_Item">
                                    <label for="Password" className="Password_lbl"><a>*</a>Password:</label> 
                                    <input id="Password" name="Password" className="Password" type="password" placeholder="Password" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" value={Password} onChange={(event) => {
                                      setPassword(event.target.value);
                                    }} title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters" required="true"/>
                                </div>
                                <div className="Reg_Item">
                                    <label for="ConfirmPassword" className="ConfirmPassword_lbl"><a>*</a>Confirm Password:</label> 
                                    <input id="ConfirmPassword" name="ConfirmPassword" className="ConfirmPassword" placeholder="Confirm Password" type="password" value={confirmPassword} onBlur={(event) => {
                                      ValidatePassword();
                                    }} onChange={(event) => {
                                      setConfirmPassword(event.target.value);
                                    }} required="true"/>
                                </div>
                                <div className="Reg_Item">
                                    <label for="StoreName" className="StoreName"><a>*</a>Store Name:</label> 
                                    <input id="RegisterStoreName" name="StoreName" className="StoreName" type="text" defaultValue={ExistingSupplier.storeName} onChange={(event) => {
                                      setStoreName(event.target.value);
                                    }} placeholder="Store Name" required="true"/>
                                </div>
                                <div className="Reg_Item">
                                    <label for="DeliveryAddress" className="DeliveryAddress_lbl"><a>*</a>Delivery Address:</label> 
                                    <input id="DeliveryAddress" name="DeliveryAddress" className="DeliveryAddress" type="text" defaultValue={ExistingSupplier.deliveryAddress} onChange={(event) => {
                                      setDeliveryAddress(event.target.value);
                                    }} placeholder="Delivery Address" required="true"/>
                                </div>
                                <div className="Reg_Item">
                                    <label for="Email" className="Email_lbl"><a>*</a>Email:</label> 
                                    <input id="Email" name="Email" className="Email" type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{6,}$" defaultValue={ExistingSupplier.email} onChange={(event) => {
                                      setEmail(event.target.value);
                                    }} placeholder="Email" required="true"/>
                                </div>
                                <div className="Reg_Item">
                                    <label for="ContactNum" className="ContactNum_lbl"><a>*</a>Contact Number:</label> 
                                    <input id="ContactNum" name="ContactNm" className="ContactNum" type="tel" defaultValue={ExistingSupplier.contactNumber} onChange={(event) => {
                                      setContactNumber(event.target.value);
                                    }} placeholder="Contact Number" required="true"/>
                                </div>   
                                <div className="Supplier_About_Wrap">
                                  <label for="Supplier_About_Store">About Store: </label>
                                  <textarea id="Supplier_About_Store" name="Supplier_About_Store" rows="4" cols="50" defaultvalue={ExistingSupplier.aboutStore} onChange={(event) => {
                                      setAboutStore(event.target.value);
                                    }} required="true"></textarea>
                                </div>
                                <div className="Supplier_TCs_Wrap">
                                  <label for="Supplier_TCs">Terms and Conditions: </label>
                                  <textarea id="Supplier_TCs" name="Supplier_TCs" rows="4" cols="50" defaultvalue={ExistingSupplier.tandC} onChange={(event) => {
                                      setTCs(event.target.value);
                                    }} required="true"></textarea>
                                </div>
                                <div className="Reg_CompanyLogo_Wrap">
                                  <div className="Reg_CompanyLogo_Input">
                                    <label for="CompanyLogo" className="Logo_lbl"><a>*</a>Store Logo:</label> 
                                    <input type="file" id="CompanyLogo" name="CompanyLogo" multiple onChange={handleLogoFileEvent} accept="image/png, image/gif, image/jpeg" required="true"/>
                                  </div>
                                </div>
    
                                  <Checkbox label="My Value"
                                            value={Termschecked}
                                            onChange={handleChange}/>
                                
                              </div>  
                            </div>  
                          }
                        </div>
                      } 
                    </div>           
                    <div className="Supplier_Reg_Wrap" aria-hidden={checked !== "Supplier" ? true : false}>
                      <div className="Supplier_Reg_upper">
                        <div className="Supplier_Wrap2">
                          <div className="Reg_Item">
                              <label for="FirstName" className="FirstName"><a>*</a>First Name:</label> 
                              <input id="RegisterFirstName" name="FirstName" className="FirstName" type="text" value={FirstName} onChange={(event) => {
                                setFirstName(event.target.value);
                              }} placeholder="First Name" required="true"/>
                          </div>
                          <div className="Reg_Item">
                              <label for="Surname" className="Surname_lbl"><a>*</a>Surname:</label> 
                              <input id="Surname" name="Surname" className="Surname" type="text" value={Surname} onChange={(event) => {
                                setSurname(event.target.value);
                              }} placeholder="Surname" required="true"/>
                          </div>
                          <div className="Reg_Item">
                              <label for="Username" className="Username"><a>*</a>Username:</label> 
                              <input id="RegisterUsername" name="Username" className="Username" type="text" value={UserName} onChange={(event) => {
                                setUserName(event.target.value);
                              }} placeholder="Username" required="true"/>
                          </div>
                          <div className="Reg_Item">
                              <label for="Password" className="Password_lbl"><a>*</a>Password:</label> 
                              <input id="Password" name="Password" className="Password" type="password" placeholder="Password" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" value={Password} onChange={(event) => {
                                setPassword(event.target.value);
                              }} title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters" required="true"/>
                          </div>
                          <div className="Reg_Item">
                              <label for="ConfirmPassword" className="ConfirmPassword_lbl"><a>*</a>Confirm Password:</label> 
                              <input id="ConfirmPassword" name="ConfirmPassword" className="ConfirmPassword" placeholder="Confirm Password" type="password" value={confirmPassword} onBlur={(event) => {
                                ValidatePassword();
                              }} onChange={(event) => {
                                setConfirmPassword(event.target.value);
                              }} required="true"/>
                          </div>
                          <div className="Reg_Item">
                              <label for="StoreName" className="StoreName"><a>*</a>Store Name:</label> 
                              <input id="RegisterStoreName" name="StoreName" className="StoreName" type="text" value={StoreName} onChange={(event) => {
                                setStoreName(event.target.value);
                              }} placeholder="Store Name" required="true"/>
                          </div>
                          <div className="Reg_Item">
                              <label for="DeliveryAddress" className="DeliveryAddress_lbl"><a>*</a>Delivery Address:</label> 
                              <input id="DeliveryAddress" name="DeliveryAddress" className="DeliveryAddress" type="text" value={DeliveryAddress} onChange={(event) => {
                                setDeliveryAddress(event.target.value);
                              }} placeholder="Delivery Address" required="true"/>
                          </div>
                          <div className="Reg_Item">
                              <label for="Email" className="Email_lbl"><a>*</a>Email:</label> 
                              <input id="Email" name="Email" className="Email" type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{6,}$" value={Email} onChange={(event) => {
                                setEmail(event.target.value);
                              }} placeholder="Email" required="true"/>
                          </div>
                          <div className="Reg_Item">
                              <label for="ContactNum" className="ContactNum_lbl"><a>*</a>Contact Number:</label> 
                              <input id="ContactNum" name="ContactNm" className="ContactNum" type="tel" value={ContactNumber} onChange={(event) => {
                                setContactNumber(event.target.value);
                              }} placeholder="Contact Number" required="true"/>
                          </div>
                          <div className="Reg_CompanyLogo_Wrap">
                            <div className="Reg_CompanyLogo_Input">
                              <label for="CompanyLogo" className="Logo_lbl"><a>*</a>Store Logo:</label> 
                              <input type="file" id="CompanyLogo" name="CompanyLogo" multiple onChange={handleLogoFileEvent} accept="image/png, image/gif, image/jpeg" required="true"/>
                            </div>  
                          </div>
                        </div>                
                      </div>
                      <div className="Supplier_Reg_Lower">
                        <div className="Supplier_About_Wrap">
                          <label for="Supplier_About_Store">About Store: </label>
                          <textarea id="Supplier_About_Store" name="Supplier_About_Store" rows="4" cols="50" value={AboutStore} onChange={(event) => {
                              setAboutStore(event.target.value);
                            }} required="true"></textarea>
                        </div>
                        <div className="Supplier_TCs_Wrap">
                          <label for="Supplier_TCs">Terms and Conditions: </label>
                          <textarea id="Supplier_TCs" name="Supplier_TCs" rows="4" cols="50" value={TCs} onChange={(event) => {
                              setTCs(event.target.value);
                            }} required="true"></textarea>
                        </div>
                      </div>
                          
                      <Checkbox label="My Value"
                                            value={Termschecked}
                                            onChange={handleChange}
                                            styling="Supplier_TCs_Wrap"/>
                     
                    </div>
                    <div className="Individual_Reg_Wrap" aria-hidden={checked !== "Individual" ? true : false}>
                      <div className="Supplier_Reg_upper">
                        <div className="Supplier_Wrap2">
                          <div className="Reg_Item">
                              <label for="FirstName" className="FirstName"><a>*</a>First Name:</label> 
                              <input id="RegisterFirstName" name="FirstName" className="FirstName" type="text" value={FirstName} onChange={(event) => {
                                setFirstName(event.target.value);
                              }} placeholder="First Name" required="true" />
                          </div>
                          <div className="Reg_Item">
                              <label for="Surname" className="Surname_lbl"><a>*</a>Surname:</label> 
                              <input id="Surname" name="Surname" className="Surname" type="text" value={Surname} onChange={(event) => {
                                setSurname(event.target.value);
                              }} placeholder="Surname" required="true"/>
                          </div>
                          <div className="Reg_Item">
                              <label for="Username" className="Username"><a>*</a>Username:</label> 
                              <input id="RegisterUsername" name="Username" className="Username" type="text" value={UserName} onChange={(event) => {
                                setUserName(event.target.value);
                              }} placeholder="Username" required="true"/>
                          </div>
                          <div className="Reg_Item">
                              <label for="Password" className="Password_lbl"><a>*</a>Password:</label> 
                              <input id="Password" name="Password" className="Password" type="password" placeholder="Password" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" value={Password} onChange={(event) => {
                                setPassword(event.target.value);
                              }} title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters" required="true"/>
                          </div>
                          <div className="Reg_Item">
                              <label for="ConfirmPassword" className="ConfirmPassword_lbl"><a>*</a>Confirm Password:</label> 
                              <input id="ConfirmPassword" name="ConfirmPassword" className="ConfirmPassword" placeholder="Confirm Password" type="password" value={confirmPassword} onBlur={(event) => {
                                ValidatePassword();
                              }} onChange={(event) => {
                                setConfirmPassword(event.target.value);
                              }} required="true"/>
                          </div>
                          <div className="Reg_Item">
                              <label for="StoreName" className="StoreName"><a>*</a>Store Name:</label> 
                              <input id="RegisterStoreName" name="StoreName" className="StoreName" type="text" value={StoreName} onChange={(event) => {
                                setStoreName(event.target.value);
                              }} placeholder="Store Name" required="true"/>
                          </div>
                          <div className="Reg_Item">
                              <label for="DeliveryAddress" className="DeliveryAddress_lbl"><a>*</a>Delivery Address:</label> 
                              <input id="DeliveryAddress" name="DeliveryAddress" className="DeliveryAddress" type="text" value={DeliveryAddress} onChange={(event) => {
                                setDeliveryAddress(event.target.value);
                              }} placeholder="Delivery Address" required="true"/>
                          </div>
                          <div className="Reg_Item">
                              <label for="Email" className="Email_lbl"><a>*</a>Email:</label> 
                              <input id="Email" name="Email" className="Email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{6,}$" type="email" value={Email} onChange={(event) => {
                                setEmail(event.target.value);
                              }} placeholder="Email" required="true"/>
                          </div>
                          <div className="Reg_Item">
                              <label for="ContactNum" className="ContactNum_lbl"><a>*</a>Contact Number:</label> 
                              <input id="ContactNum" name="ContactNm" className="ContactNum" type="tel" value={ContactNumber} onChange={(event) => {
                                setContactNumber(event.target.value);
                              }} placeholder="Contact Number" required="true"/>
                          </div>
                          <div className="Reg_CompanyLogo_Wrap">
                            <div className="Reg_CompanyLogo_Input">
                              <label for="CompanyLogo" className="Logo_lbl"><a>*</a>Store Logo:</label> 
                              <input type="file" id="CompanyLogo" name="CompanyLogo" multiple onChange={handleLogoFileEvent} accept="image/png, image/gif, image/jpeg" required="true"/>
                            </div>
                          </div>
                              
                          <Checkbox label="My Value"
                                            value={Termschecked}
                                            onChange={handleChange}
                                            />

                        </div>   
                      </div>
                    </div>
                </div>     
            </div>
            <div className="modalActions">
              <div className="actionsContainer">
                <button onClick={ CreateUserObject } type="submit" id="RegSignUpBtn" className="RegSignUpBtn BtnGrey">
                    Sign Up
                </button>
                <button className="RegCancelBtn BtnLightGrey" onClick={() => OpenRegister(false)}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

export default Registration;
