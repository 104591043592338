import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import './Manager.scss';
import { useSelector, useDispatch } from 'react-redux';
import { SelectedMenu } from '../../Actions';


export default props => {

  let dispatch = useDispatch();

  let CurrentUser = useSelector(state => state.CurrentUser);

  return (
    <Menu>
      {CurrentUser.roleID == "1" ?
      <div>
        <a className="menu-item" onClick={() => {dispatch(SelectedMenu("Stats"));}}>
          Stats
        </a>
        <a className="menu-item" onClick={() => {dispatch(SelectedMenu("Approved"));}}>
          Store Approvals
        </a>
        <a className="menu-item" onClick={() => {dispatch(SelectedMenu("Featured"));}}>
          Featured Supplier
        </a>
        <a className="menu-item" onClick={() => {dispatch(SelectedMenu("User"));}}>
          Manage Users
        </a>
        <a className="menu-item" onClick={() => {dispatch(SelectedMenu("SearchStats"));}}>
          Search Stats
        </a>
      </div> 
      :
        <div>
          <a className="menu-item" onClick={() => {dispatch(SelectedMenu("Stats"));}}>
            Stats
          </a>
          <a className="menu-item" onClick={() => {dispatch(SelectedMenu("Approved"));}}>
            Store Approvals
          </a>
          <a className="menu-item" onClick={() => {dispatch(SelectedMenu("SearchStats"));}}>
            Search Stats
          </a>
        </div>
      }
      
    </Menu>
  );
};