import {Routes, Route, useNavigate} from 'react-router-dom';
import {React,useState, useEffect,useRef, startTransition} from "react";
import Api from '../../AxiosRest/AxiosRest';
import "./Documentation.scss"
import Swal from 'sweetalert2';

//State imports
import { useSelector, useDispatch } from 'react-redux';
import { Login, CurrentUser, CurrentProduct, ViewedUser, AllStores} from '../../Actions';

function CompanyIndex(){

    let dispatch = useDispatch();
    let navigate = useNavigate();

    let [IndustrySec ,setIndustrySec] = useState([]);
    let [AllStores ,setAllStores] = useState([]);
    let AllStoresRedux = useSelector(state => state.AllStores);


    //this function will fetch all the industry sectors with the counts of all stores in each sector
    let GetIndustrySecStats = async() =>{
        try{
            await Api.get('/store/Stores/GetIndustrySectorStats').then((response) => {
                setIndustrySec(response.data);
            });
        }
        catch(exception){   
            Swal.fire(
                'Oops',
                'There was an error fetching the required information',
                'error'
            )
        }  
    }

    /*let GetStores = async() =>{
        if(AllStoresRedux == ""){ //attempting to limit the number of times an api call is made to reduce loading times
            try{
                await Api.get('/store/Stores/GetAllStores').then((response) => {//fetching all the stores from the database
                    setAllStores(response.data);
                    dispatch(AllStores(response.data));
                });
            }
            catch(exception){   
                Swal.fire(
                    'Oops',
                    'There was an error fetching the required information',
                    'error'
                )
                //navigate("/");
            }
        }
        else{
            setAllStores(AllStoresRedux);
        }
    }*/

    function getStores(){
        if(AllStoresRedux == ""){
            Swal.fire({
                title: 'Loading',
                html: '<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>',
                showCancelButton: false,
                showConfirmButton: false,
                allowOutsideClick: false,
                didOpen: () => {
                  Swal.showLoading();
                }
            });
            Api.get('/store/Stores/GetAllStores')
            .then((response) => {//fetching all the stores from the database
                setAllStores(response.data);
                dispatch(AllStores(response.data));
                Swal.close();
            })
            .catch((response) => {
                Swal.close();
            })
        }
        else{
            setAllStores(AllStoresRedux)
        }
    }

    useEffect(() => {
        getStores();
        GetIndustrySecStats();
    }, []);

    return(
        <div id="CompanyIndex">
            <div>
                <div className="CI_Page_Title_Wrap">
                    <div className="CI_Page_Title">
                        <h3>
                            Company Index
                        </h3>
                    </div>
                </div>
                <div className="CI_Info_Wrap">
                    <div className="CI_IndustrySecs">
                        <ul>
                            {IndustrySec.map((item, index) => (
                                <li>{item.industrySectorDescription} <a>&#40;{item.storeCount}&#41;</a></li>
                            ))}
                        </ul>
                    </div>
                    <div className="CI_Stores">
                        {AllStores.map((item, index) => (
                            <div className="CI_Store_Wrap">
                                <div className="CI_Store_Logo">
                                    <div>
                                        <img src={item.companyLogo} width="100%" height="100%"/>
                                    </div>    
                                </div>         
                                <div className="CI_Desc_Wrap">
                                    <div className="CI_Store_Title">
                                        <h3>{item.storeName}</h3>
                                    </div>
                                    <div className="CI_Store_Desc">
                                        <p>{item.aboutStore}</p>
                                    </div> 
                                </div>    
                                <div className="CI_ReadMore" >
                                    <button className="BtnGrey CI_SeeMoreBtn" onClick={()=>{
                                        dispatch(CurrentProduct(item)); navigate("/UserStore")}}>View Store</button>
                                </div>       
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CompanyIndex;