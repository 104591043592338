import React, { useState, useEffect } from 'react';
import '../FullBanner/FullBanner.css';
import { useNavigate } from 'react-router-dom';
import MainBanner from '../../../Images/Banner1.png';


function FullBanner(){

    let navigate = useNavigate();

    const Navigate = () => {
        navigate('/Collection');
    }

    return(
        <div id="FullBanner">
            <div className="FullBanner_Image" style={{backgroundImage: `url(${MainBanner})`}}>
                <div className="Banner_Shout">
                    <h1><strong>We Provide The Best</strong>
                    <br/>Startup Company Solutions!</h1>
                    <p>We provide a all in one startup solution. <br/>Each solution is tailored to suit your company's needs.</p>
                </div>
                <div className="FullBanner_ShopNow_Wrap">
                    <div className="FullBanner_ShopNow">
                        <h3>Shop Our Great Products</h3>
                        <button onClick={Navigate} id="Btn" className="ShopNowBtn">Shop Now</button>
                    </div>
                </div>
            </div>            
        </div>
    );
}

export default FullBanner;