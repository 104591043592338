import '../Home/Login/LoginPopUp';
import {React,useState, useEffect,useRef, startTransition} from "react";
import { RiCloseLine } from "react-icons/ri";
import Api from '../../AxiosRest/AxiosRest';
import { useNavigate } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';

const CollectionMessagePop = ({ MessageIsOpen }) => {

    
    const [isLogin, setIsLogin] = useState(true);
    const [RegisterIsOpen, OpenRegister] = useState(false);
    let CurrentProduct = useSelector(state => state.CurrentProduct);

  
    return (
      <>
        <div className="darkBG" onClick={() => MessageIsOpen(false)} />
        <div className="MessageCentered">
          <div className="Modal">
            <div className="modalHeader">
              <h3 className="heading">DETAILS</h3>
            </div>
            <button className="closeBtn" onClick={() => MessageIsOpen(false)}>
              <RiCloseLine style={{ marginBottom: "-3px" }} />
            </button>
            <div className="modalContent">
                <h3>{CurrentProduct.productName}</h3>
              <p>StoreName: {CurrentProduct.storeDetails.storeName}</p>
              <p>Email: {CurrentProduct.storeDetails.contactEmail}</p>
              <p>Tel: {CurrentProduct.storeDetails.contactNumber}</p>
              <p>Price: {CurrentProduct.productPrice}</p>
  
            </div>
            <div className="modalActions">
              <div className="actionsContainer">
                <button className="cancelBtn" onClick={() => MessageIsOpen(false)}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

export default CollectionMessagePop;
