export const Login = () => {
    return{
        type: 'LOGGEDIN'
    };
};

export const CurrentUser = user => {
    return{
        type: 'CURRENTUSER',
        payload: user
    };
};

export const Product = product => {
    return{
        type: 'PRODUCT',
        payload: product
    }
}

export const AddToCart = (product) => ({
    type: 'ADD_TO_CART',
    payload: product
});

export const RemoveFromCart = (productID) => ({
    type: 'REMOVE_FROM_CART',
    payload: productID
});

export const CurrentProduct = Product => {
    return{
        type: 'CURRENTPRODUCT',
        payload: Product
    }
}

export const FilteredProduct = Product => {
    return{
        type: 'FILTERPRODUCT',
        payload: Product
    }
}

export const ViewedUser = user => {
    return{
        type: 'VIEWEDUSER',
        payload: user
    }
}

export const SelectedMenu = item => {
    return{
        type: 'SELECTEDMENU',
        payload: item
    }
}

export const AllStores = stores => {
    return{
        type: 'ALLSTORES',
        payload: stores
    }
}

export const FilterStores = stores => {
    return{
        type: 'FILTERSTORES',
        payload: stores
    }
}

export const AllUsers = users => {
    return{
        type: 'ALLUSERS',
        payload: users
    }
}

export const AllRoles = roles => {
    return{
        type: 'ALLROLES',
        payload: roles
    }
}

export const SelectedStore = store => {
    return{
        type: 'SELECTEDSTORE',
        payload: store
    }
}