import '../.././App.css';
import FullBanner from '../Home/FullBanner/FullBanner';
import {React,useState, useEffect,useRef, startTransition} from "react";
import FeaturedContent from '../Home/FeaturedContent/FeaturedContent';
import Newsletter from '../Home/Newsletter/Newsletter';


function Home() {

  function ScrollToTop(){
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    })
  }

  useEffect(() => {
      ScrollToTop();
  }, []);

  return (
    <div>
        <FullBanner></FullBanner>
        <FeaturedContent></FeaturedContent>
        <Newsletter></Newsletter>     
    </div>
  );
}

export default Home;