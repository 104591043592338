import '../Documentation/Documentation.scss'
import '../Manager/Manager.scss';
import Api from '../../AxiosRest/AxiosRest';
import { useNavigate } from 'react-router-dom';
import {React,useState, useEffect,useRef, startTransition} from "react";
import Swal from 'sweetalert2';

//State imports
import { useSelector, useDispatch } from 'react-redux';
import { AllStores, CurrentProduct, CurrentUser } from '../../Actions';

function ContactUs () {

    let navigate = useNavigate();

    const [name, setName] = useState("");
    const [contactNum, setContactNum] = useState("");
    const [email, setEmail] = useState("");
    const [description, setDescription] = useState("");
    const [enquiry, setEnquiry] = useState("");
    const [enquiryTypes, setEnquiryTypes] = useState([]);
    let Contact = {};


    function BuildContactObject(){
        if(name === ""){
            Swal.fire(
                'Oops!',
                'Please ensure Contact Name is filled in',
                'warning'
            )
            return;
        }else{
            Contact.contactName = name;
        }
        if(contactNum === ""){
            Swal.fire(
                'Oops!',
                'Please ensure Contact Number is filled in',
                'warning'
            )
            return;
        }else{
            if(validatePhone(contactNum)) {
                Contact.contactNumber = contactNum;
              } else {
                Swal.fire(
                  'Woops',
                  'Please provide a Valid Email Address',
                  'warning'
                )
                return;
            }     
        }
        if(email === ""){
            Swal.fire(
                'Oops!',
                'Please ensure Email is filled in',
                'warning'
            )
            return;
        }else{
            if(validateEmail(email)) {
                Contact.email = email;
              } else {
                Swal.fire(
                  'Woops',
                  'Please provide a Valid Email Address',
                  'warning'
                )
                return;
            }
        }
        if(description === ""){
            Swal.fire(
                'Oops!',
                'Please ensure a Description is filled in',
                'warning'
            )
            return;
        }else{
            Contact.description = description;
        }
        if(enquiry === ""){
            Swal.fire(
                'Oops!',
                'Please ensure an Enquire Type is selected',
                'warning'
            )
            return;
        }else{
            Contact.contactUsOptionID = enquiry;
        }
        /*Contact = {
            contactName: name,
            contactNumber: contactNum,
            email: email,
            description: description,
            contactUsOptionID: enquiry
        }*/

        SendEnquiry();
    }

    //RegEx pattern to validate the Phone Number
    function validatePhone(contactNum) {
        let res = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
        return res.test(contactNum);
    }

    //RegEx pattern to validate the email address
    function validateEmail(Email) {
        let res = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        return res.test(Email);
    }

    function SendEnquiry(){
        Api.post('/store/StoreUser/CreateNewContactUsRequest', 
            Contact
          )
          .then((response) => {
            Swal.fire(
              'Great!',
              'Your enquiry has been sent!',
              'success'
            )
        });
    }

    //used to fetch the types of enquiries
    function FetchTypes(){
        try{
            Api.get('/store/StoreUser/GetContactOptions').then((response) => {
                setEnquiryTypes(response.data);
            });
        }
        catch(exception){   
            
        }  
    }

    useEffect(() => {
        FetchTypes();
    }, []);

    return(
        <div id="ContactUs">
            <div className="Contact_Wrap">
                <div className="Contact_Title">
                    <h1>Contact Us</h1>
                </div>
                <div className="Contact_Inner">
                    <div className="Contact_Text">
                        <p>You can give us your feedback anonymously but, if you would like a reply to your feedback, please let us have your contact details or email us directly on <a href="mailto:admin@scnet.co.za">admin@scnet.co.za</a> . If you require immediate assistance please contact our call centre on 087 094 9990.</p>
                    </div>
                    <div className="Contact_Inputs">
                        <div>
                            <label for="Name">Name:</label>
                            <input type="text" id="Contact_Name" onChange={(event) => setName
                            (event.target.value)} name="Name"/>
                        </div>
                        <div>
                            <label for="ContactNum">Contact Number:</label>
                            <input type="tel" id="Contact_ContactNum" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" onChange={(event) => setContactNum
                            (event.target.value)} name="ContactNum"/>
                        </div>
                        <div>
                            <label for="Email">Email Address:</label>
                            <input type="email" id="Contact_Email" onChange={(event) => setEmail
                            (event.target.value)} name="Email"/>
                        </div>
                        <div>
                            <label for="Description">Description of issue:</label>
                            <textarea type="textarea" id="Contact_Description" name="Description" rows="8" cols="50" onChange={(event) => setDescription
                            (event.target.value)}/>
                        </div>
                        <div>
                            <label for="EnquireType">Enquire Type:</label>
                            <select name="EnquireType" id="Contact_EnquireType" onChange={(e)=> {setEnquiry(e.target.value)}}>
                                <option value="0" selected>Enquire Type:</option>
                                {enquiryTypes.map((item, index) => (
                                    <option value={item.contactUsOptionID}>
                                        {item.contactUsDescription}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="Contact_Btns">
                        <button id="Contact_Cancel" className="BtnGrey" onClick={() => {navigate("/")}}>Cancel</button>
                        <button id="Contact_Submit" type="submit" className="BtnGrey" onClick={() => {BuildContactObject()}}>Submit</button>
                    </div>
                </div>   
            </div>
        </div>
    );
}

export default ContactUs;