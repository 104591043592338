import '../Documentation/Documentation.scss'

function AboutUs() {
    return(
        <div id="AboutUs_Wrap">
            <div className="AboutUs_Inner_Wrap">
                <div className="About_Title">
                    <h1>About Us</h1>
                </div>
                <div className="About_Content">
                    <div className="About_About">
                        <h3>&#8680; About SCNet</h3>
                        <p>SCNet provides procurement solutions to assist buyers to discover credible and empowered suppliers, with powerful and secure online quote management technology.<br/>
                        To further assist SMMEs, SCNet provides one of the most comprehensive procurement opportunity / tender research services available in the country for a fraction of the cost of traditional vendors in this space. SMMEs can also make use of the online catalogue solution to setup a cost effective eCommerce solution.<br/>
                        SCNet provides corporate clients with lite to comprehensive online vendor management solutions including digital vendor registration, health-check dashboards and automated preferential procurement reporting. The SCNet solution is a credible Access to Markets solution that fully qualifies as Enterprise and Supplier Development.<br/>
                        SCNet also provides corporate clients with digital Reverse Auction and Forward Auction solutions saving our clients Rmillions - we can do the same for you.<br/>
                        For more information on the comprehensive suite of procurement solutions contact the call centre on 087 094 9990 for more information.</p>
                    </div>
                    <div className="About_Vision">
                        <h3>&#8680; Vision</h3>
                        <p>To be the premier online business marketplace for corporates and SMMEs, serving as an enabler to economic and employment growth, where SCNet is recognized as the trusted partner to deliver best of breed procurement solutions, unlocking efficiencies, savings and time.</p>
                    </div>
                    <div className="About_Mission">
                        <h3>&#8680; Mission</h3>
                        <p>To serve and support clients to achieve their vision of procurement excellence through the implementation of our state of the art online sourcing and vendor management technology. Supported by an innovative team with procurement process expertise, we ensure that organisations deliver on their procurement goals and initiatives. Through SCNet’s effective technology and support team we deliver a marketplace where economic activity between trusted and validated SMMEs is stimulated.</p>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default AboutUs;