import '../Manager/Manager.scss';
import Api from '../../AxiosRest/AxiosRest';
import { useNavigate, useLocation } from 'react-router-dom';
import {React,useState, useEffect,useRef, startTransition} from "react";
import Sidebar from './SideBar';
import Approval from './Store_Approvals';
import Stats from './Store_Stats';
import FeaturedPop from './FeaturedModel';

//State imports
import { useSelector, useDispatch } from 'react-redux';
import { Login, CurrentUser, Product, AddToCart, CurrentProduct, FilteredProduct, AllStores, FilterStores, SelectedStore} from '../../Actions';


function FeaturedSupplier(){

    const [SearchTerm, setSearchTerm] = useState("");
    const [isOpenFeatured, FeaturedIsOpen] = useState(false);
    let dispatch = useDispatch();
    let allStores = [];

    //fetching all products
    allStores = useSelector(state => state.AllStores);
    let FilteredStores = useSelector(state => state.FilteredStores);

    function Search(){
        try{
            if(SearchTerm != ""){
                dispatch(FilterStores(allStores.filter((item) => item.storeName.includes(SearchTerm))));
                FilteredStores = allStores.filter((item) => item.storeName.includes(SearchTerm));
            }  
        }
        catch(error){
            console.log("error");
        }
        
    }

    function ScrollToTop(){
        window.scrollTo({
          top: 0,
          behavior: 'instant',
        })
      }


    //used to fire api call as soon as the page loads
    useEffect(() => {
        Search();
        ScrollToTop();
    }, []);

    return(
        <div id="FeaturedSupplier">
            <div>
                <div className="F_Search">
                    <input value={SearchTerm} 
                    onChange={(event) => { setSearchTerm(event.target.value);}} className="F_SearchTerm" type="text" placeholder="Store Name" required />
                    <button className="BtnGrey" onClick={() => {Search()}}>Search</button>
                </div>
                <div className="F_Stores_Wrap">
                    <div>
                        {!FilteredStores ?
                            <div>
                                {!allStores ?
                                    <div>
                                        <h3>There are no stores currently at this time, please come back later.</h3>
                                    </div>    
                                :
                                    <div className="F_Stores_Inner">        
                                        {allStores.map((item, index) => (
                                        <div key={index} className="M_F_StoreCard_Outer_Wrap">
                                            <div className="M_F_StoreCard_Wrap">
                                                <div className="M_F_StoreCard_Col1">
                                                    <div>
                                                        <img src={item.companyLogo} width="100%" height="100%"/>
                                                    </div>     
                                                </div>
                                                <div className="M_F_StoreCard_Col2">
                                                    <div className="M_F_StoreCard_About">
                                                        <h2>Store: {item.storeName}</h2>
                                                    </div>
                                                    <div className="M_F_StoreCard_Desc">
                                                        <p>{item.aboutStore}</p>
                                                    </div>            
                                                </div>
                                                <div className="M_F_StoreCard_Col3">
                                                    <div className="M_F_StoreCard_Address">
                                                        <p>Address: {item.deliveryAddress}</p>
                                                    </div>
                                                    <div className="M_F_StoreCard_Btn">
                                                        <button className="M_F_OpenFeatured BtnGrey" onClick={() => {dispatch(SelectedStore(item)); FeaturedIsOpen(true);}}>
                                                            Open Featured
                                                        </button>
                                                        {isOpenFeatured && <FeaturedPop FeaturedIsOpen={FeaturedIsOpen}/>}
                                                    </div>            
                                                </div>
                                            </div>
                                        </div>
                                        ))}
                                    </div>
                                }
                            </div>        
                            :
                            <div className="F_Stores_Inner">        
                                {FilteredStores.map((item, index) => (
                                <div key={index} className="M_F_StoreCard_Outer_Wrap">
                                    <div className="M_F_StoreCard_Wrap">
                                        <div className="M_F_StoreCard_Col1">
                                            <div>
                                                
                                            </div>    
                                        </div>
                                        <div className="M_F_StoreCard_Col2">
                                            <div className="M_F_StoreCard_About">
                                                <h2>Store: {item.storeName}</h2>
                                            </div>
                                            <div className="M_F_StoreCard_Desc">
                                                <p>{item.aboutStore}</p>
                                            </div>            
                                        </div>
                                        <div className="M_F_StoreCard_Col3">
                                            <div className="M_F_StoreCard_Address">
                                                <p>Address: {item.deliveryAddress}</p>
                                            </div>
                                            <div className="M_F_StoreCard_Btn">
                                                <button className="M_F_OpenFeatured" onClick={() => { dispatch(SelectedStore(item)); FeaturedIsOpen(true);}}>
                                                    Open Featured
                                                </button>
                                                {isOpenFeatured && <FeaturedPop FeaturedIsOpen={FeaturedIsOpen}/>}
                                            </div>            
                                        </div>
                                    </div>
                                </div>
                                ))}
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FeaturedSupplier;