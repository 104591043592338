import '../Newsletter/Newsletter.scss';
import Api from '../../../AxiosRest/AxiosRest';
import { useNavigate, useLocation } from 'react-router-dom';
import {React,useState, useEffect} from "react";
import Swal from 'sweetalert2';

//State imports
import { useSelector, useDispatch } from 'react-redux';
import { AllStores, CurrentProduct, CurrentUser } from '../../../Actions';

function Newsletter (){

    let [email, setEmail] = useState("");

    function subscribeUser(){
        if(email !== null){
            Api.post('/store/StoreUser/RegisterForNewsletter', 
                { Email: email }
            )
            .then((response) => {
                Swal.fire(
                    'Great!',
                    'Thanks for subscribing to our newsletter!',
                    'success'
                )
                setEmail("");
            });
        }
    }

    

    return (
        <div id="Newsletter_Section">
            <div className="Newsletter_Wrap">
                <div className="Newsletter_Title">
                    <h2>Subscribe to Our Newsletter</h2>
                </div>
                <div className="Newsletter_Input">
                    <label for="Newsletter_Email">Email: </label>
                    <input name="Newsletter_Email" id="Newsletter_Email" type="email" value={email} onChange={(event) => {setEmail(event.target.value)}}></input>
                </div>
                <div className="Newsletter_Button">
                    <button className="BtnGrey BtnNewsletter" type="submit" onClick={() => { subscribeUser() }}>Subscribe</button>
                </div>
            </div>
        </div>
    );
}

export default Newsletter;