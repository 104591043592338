import NavBar from "../NavBarComponent/NavBar";
import "../NavBarComponent/css/NavBar.css";
import { Router } from "react-router-dom";


const Header = () => {
    return (
        <header>
            <div className="Nav-Area">
                <NavBar/>
            </div>  
        </header>
    );
};

export default Header;