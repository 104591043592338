import AccountHome from "../Components/Account/AccountHome";

function Accounts() {
    return(
        <div>
            <AccountHome></AccountHome>
        </div>
    )
}

export default Accounts;