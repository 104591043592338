import {useNavigate} from 'react-router-dom';
import {React,useState, useEffect} from "react";
import Api from '../../AxiosRest/AxiosRest';
import "../Account/Accounts.scss"
import CollectionMessagePop from "../Collection/collecttionMessagePop";
import SCNetTreeLogo from '../../Images/SCNetTreeLogo.png';
import Swal from 'sweetalert2';

//State imports
import { useSelector, useDispatch } from 'react-redux';
import {CurrentProduct} from '../../Actions';


function MockAccount() {

    let dispatch = useDispatch();
    const navigate = useNavigate();
    let Currentuser = useSelector(state => state.CurrentUser);
    let CurProduct = useSelector(state => state.CurrentProduct);
    const [isOpenMessage, MessageIsOpen] = useState(false);
    let [UserStore, setUserStore] = useState([]);
    let NewObj = {};

    let date = new Date();

    let FetchUserProducts = async () => {
        try{
            Swal.fire({
                title: 'Loading',
                html: '<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>',
                showCancelButton: false,
                showConfirmButton: false,
                allowOutsideClick: false,
                didOpen: () => {
                  Swal.showLoading();
                }
            });
            Api
            .post('/store/Products/GetSelectedCatalog', 
                {id: CurProduct.catalogID}
                )
            .then((response) => {
                Swal.close();
                setUserStore(response.data);
                console.log(response.data);
            });
        }
        catch(error){
            Swal.close();
            Swal.fire(
                'error',
                'We could not fetch that stores details right now, please try again later',
                'error'
            )
            navigate("/");
        }        
    }

    function buildProdObj(index){   
        NewObj={
            productID: UserStore.products[index].productID, productName: UserStore.products[index].productName, catalogID: UserStore.catalogID, productDescription: UserStore.products[index].productDescription, quantity: UserStore.products[index].quantity, industrySectorID: UserStore.products[index].industrySectorId, productPrice: UserStore.products[index].productPrice, storeDetails: {storeName: UserStore.storeName, contactNumber: UserStore.contactNumber, contactEmail: UserStore.email}, imagesUrls: UserStore.products[index].imagesUrls
        }
    }

    function ScrollToTop(){
        window.scrollTo({
          top: 0,
          behavior: 'instant',
        })
    }

    useEffect(() => {
        ScrollToTop();
        FetchUserProducts();
    }, []);


    return (
        <div id="MockAccount">
            <div className="">
                <div className="MockAccount_Info_Wrap">
                    <div className="MockAccount_Content">
                        <div>
                        {!UserStore.storeName ? 
                            <h1 className="MA_FullName">{UserStore.firstName} {UserStore.surname}</h1>
                        :
                            <h1>{UserStore.storeName}</h1>
                        } 
                        </div>
                        <div>
                            <p>{UserStore.aboutStore}</p>
                        </div>
                    </div>
                    <div className="MockAccount_Man">
                        {Currentuser.adminID != undefined ?
                            <div>
                                <button className="BtnGrey" onClick={() => {navigate(-1)}}>Return to Previous Page</button>
                            </div>    
                            :
                            <div>
                                <button className="BtnGrey" onClick={() => {navigate(-1)}}>Return to Previous Page</button>
                            </div>    
                        }
                    </div>    
                </div>
                <div className="MockAccount_Products_Wrap">
                    <div className="MockAccount_Condition">
                        {!UserStore.products ?
                            <div>
                                <h3>Sorry this supplier has not created any porducts yet, please check in again soon.</h3>
                            </div>    
                            :
                            <div>
                                {UserStore.products.map((item, index) => (
                                    <div key={index} id="Prod_Card">
                                        <div className="Prod_Card">
                                            {Date.parse(item.endDate) >= date.getTime() && item.comparePrice > 0 ?
                                                <div class="ribbon"><span>SALE</span></div>
                                                :
                                                <div></div>
                                            }
                                            <div className="Prod_Card_Img" onClick={()=>{
                                                buildProdObj(index); dispatch(CurrentProduct(NewObj)); navigate("/Product");}}>
                                                {item.imagesUrls.length === 0 || item.imagesUrls[0].includes("SCNetTreeLogo") ?
                                                    <img src={SCNetTreeLogo} width="150px" height="150px" alt='ProductFeatureImage'/>
                                                    :
                                                    <img src={item.imagesUrls[0]} width="150px" height="150px" alt='ProductFeatureImage'/>
                                                }
                                            </div>    
                                            <div className="Prod_Card_Text" onClick={()=>{
                                                    buildProdObj(index); dispatch(CurrentProduct(NewObj)); navigate("/Product");}}>
                                                <h4>{item.productName}</h4>
                                                <p><strong>R</strong> {item.productPrice}
                                                {Date.parse(item.endDate) >= date.getTime() && item.comparePrice > 0 ?
                                                    <a> SALE Price: {item.comparePrice}</a>
                                                :
                                                    <a></a>
                                                }</p>
                                                <p className="Prod_Card_Desc">{item.productDescription}</p>
                                            </div>
                                            <div className="Prod_Card_Btns">
                                                <button className="Prod_Card_BN BtnGrey" onClick={() => {buildProdObj(index); dispatch(CurrentProduct(NewObj)); MessageIsOpen(true);}}>
                                                    View Details
                                                </button>
                                                {isOpenMessage && <CollectionMessagePop MessageIsOpen={MessageIsOpen}/>}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>  
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MockAccount;