import '../Manager/Manager.scss';
import Api from '../../AxiosRest/AxiosRest';
import { useNavigate } from 'react-router-dom';
import {React,useState, useEffect,useRef, startTransition} from "react";

//State imports
import { useSelector, useDispatch } from 'react-redux';
import { Login, CurrentUser } from '../../Actions';

function M_Login(){

    const [MUsername, setMUsername] = useState('');
    const [MPassword, setMPassword] = useState('');
    let navigate = useNavigate();
    let dispatch = useDispatch();
    let LoggedInUser;

    // fire login api call to send data and return current user object
    let MLoginUser =  () => {
        Api.post('/store/Manager/LoginAdminUser', {
          username: MUsername,
          Password: MPassword,
        }).then((response) => {
          dispatch(CurrentUser(response.data));
          LoggedInUser = response.data;
          window.sessionStorage.setItem('LoggedInUser', JSON.stringify(LoggedInUser));
          navigate("/ManagerHome");
        }).catch(function (error){//error handling if incorrect info is entered
          alert("Incorrect username or password");
          setMUsername("");
          setMPassword("");
        })
    };

    return(
        <div id="M_Login">
            <div>
                <div className="M_Login_Title">
                    <h3>LOGIN</h3>
                </div>
                <div className="M_Login_Wrap">
                    <div>
                        <label for="M_LoginEmail" className="">Username</label> 
                        <input value={MUsername} 
                        onChange={(event) => {
                            setMUsername(event.target.value);
                        }} id="M_LoginUsername" className="" type="text" placeholder="Username" required />
                    </div>
                    <div>
                        <label for="M_LoginPassword" className="">Password</label> 
                        <input value={MPassword} 
                        onChange={(event) => {
                            setMPassword(event.target.value);
                        }} id="M_LoginPassword" className="" type="password" placeholder="Password" required />
                    </div>
                </div>
                <div className="M_Login_Btns">
                    <button 
                        onClick={ MLoginUser } className="M_loginBtn">
                        Login
                    </button>
                    <button className="M_LoginCancelBtn" onClick={() => navigate("/")}>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    )
}

export default M_Login;