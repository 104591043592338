import ProductTemplate from '../Components/Product/ProductTemplate';

function ProductPage() {
    return (
      <div>
          <ProductTemplate/>
      </div>
    );
  }
  
  export default ProductPage;