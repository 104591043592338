import '../Collection/Collection.scss';
import {React,useState, useEffect} from "react";
import Api from '../../AxiosRest/AxiosRest';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CollectionMessagePop from './collecttionMessagePop';
import Swal from 'sweetalert2';
import SCNetLogo from '../../../src/Images/SCNetStackLogo.png';
import {FontAwesomeIcon, falight} from '@fortawesome/react-fontawesome'
import {faFilter} from '@fortawesome/free-solid-svg-icons'
import { LazyLoadImage } from 'react-lazy-load-image-component';

//State imports
import { useSelector, useDispatch } from 'react-redux';
import {CurrentUser, Product, CurrentProduct, FilteredProduct } from '../../Actions';

function Collection(){

    //creating instances of various methods that I will be calling
    const [SearchTerm, setSearchTerm] = useState("");
    let dispatch = useDispatch();
    let navigate = useNavigate();
    let [IndustrySec ,setIndustrySec] = useState([]);
    const [isOpenMessage, MessageIsOpen] = useState(false);
    let Filtered = [];
    let CurrentCart = {};
    let selectedFilter = 0;
    let selectedOrder = 0;
    let ProdTitle = [];//variable for search function
    let StoreTitle = [];//variable for search function
    let location;
    let [searchParams, setSearchParams] = useSearchParams();
    let Industry = 0;
    let Order = 0;
    let Event = "";
    let searchResults;

    let date = new Date();

    

    //recieving held Products in state
    let User = JSON.parse(window.sessionStorage.getItem('LoggedInUser'));
    

    let Products = useSelector(state => state.Product);
    let FilteredProducts = useSelector(state => state.FilteredProduct);
    CurrentCart = useSelector(state => state.CartReducer);
    let currentUser = useSelector(state => state.CurrentUser);

    if(currentUser == " "){
        dispatch(CurrentUser(User));
    }

    function updateFilter(selectedFilter, selectedOrder){
        Industry = searchParams.get('Industry');//getting the current values from the URL
        Order = searchParams.get('Order');

        
        if(selectedFilter > 0){//if there is a new filter then change the url variable
            Industry = selectedFilter;
        }
        else{
            if(Industry < 1){
                Industry = 0;
            }
        }

        if(selectedOrder > 0){
            Order = selectedOrder;
        }
        else{
            if(Order < 1){
                Order = 0;
            }
        }
           

        const url = new URL(window.location);//set the URL to the new variable
        url.searchParams.set('Industry', Industry);
        url.searchParams.set('Order', Order);
        window.history.pushState({}, '', url);


        if(FilteredProducts.length > 0){

            FilteredProducts.map((item, index) => {
                if(selectedFilter == 0){
                    Filtered.push(item);
                }
                else{
                    if(item.industrySectorID == selectedFilter){
                        Filtered.push(item);
                    }
                }
            })

            switch(Order) {
                case "1":
                    Filtered.sort((a,b) => b.productPrice - a.productPrice);
                  break;
                case "2":
                    Filtered.sort((a,b) => a.productPrice - b.productPrice);
                  break;
                case "3":
                    Filtered.sort((a,b) => Date.parse(b.dateCreated) - Date.parse(a.dateCreated));
                  break;
                case "4":
                    Filtered.sort((a,b) => Date.parse(a.dateCreated) - Date.parse(b.dateCreated));
                  break;    
                default:
                  
            }
            dispatch(FilteredProduct(Filtered));
        }
        
    }

    function LoadFilterCheck(){
        Industry = searchParams.get('Industry');//getting the current values from the URL
        Order = searchParams.get('Order');

        if(Industry > 0 || Order > 0){
            updateFilter();
        }
    }



    //search function that fires the searching procedure to look through the search data
    function Search(){
        try{
            if(currentUser != null){
                if(SearchTerm != ""){
                    dispatch(FilteredProduct(searchResults));
                    
                }
                else{
                    dispatch(FilteredProduct(Products));
                }
            }         
        }
        catch(error){
            
        } 
    }

    function FireSearchLog(){
        if(currentUser != null){
            if(SearchTerm != ""){
                ProdTitle = Products.filter((item) => item.productName.toLowerCase().includes(SearchTerm.toLowerCase()));
                StoreTitle = Products.filter((item) => item.storeDetails.storeName.toLowerCase().includes(SearchTerm.toLowerCase()));
                searchResults = [...new Set([...ProdTitle, ...StoreTitle])];
    
                let results = searchResults.length;
                let user = currentUser.id;
                
                let SearchLog = {SearchTerm: SearchTerm, CompanyName: currentUser.storeName, UserID: user, NumberOfResults: results};
    
                Api.post('/store/StoreStats/AddNewSearch', SearchLog)
                .then((response) => {
                    
                })
                .catch((response) => {
    
                })
            }
        }
        else{
            Swal.fire(
                'Oops',
                'Looks like you arent logged in, please log in to search through listed products',
                'warning'
            )
        }
    }


    //Firing API call to recieve full list of available products
    let GetProducts = async() =>{
        Swal.fire({
            title: 'Loading',
            html: '<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>',
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
        });
        try{
            Api.get('/store/Products/GetProducts').then((response) => {
                dispatch(Product(response.data));
                dispatch(FilteredProduct(response.data));
                Swal.close();
                LoadFilterCheck();
            });
        }
        catch(exception){   
            Swal.close();
        }  
    }

    //Firing API call to recieve full list of Industry Sectors
    let GetIndustrySec = async() =>{
        try{
            await Api.get('/store/Products/GetIndustrySectors').then((response) => {
                setIndustrySec(response.data.industrySectors);
            });
        }
        catch(exception){   
            
        }  
    }


    //this function fires the method to show the user the details of the product they are trying to view
    function fireMessage(index){
        if(currentUser == " " || currentUser == null){
            Swal.fire(
                'Oops',
                'Looks like you arent logged in, please log in to view product details.',
                'warning'
            )
        }
        else{
            var domain = FilteredProducts[index].storeDetails.contactEmail;
            var subject = FilteredProducts[index].productName;
            var link = "mailto:" + domain + "?subject=Purchase%20of%20Product%20" + subject;
            Swal.fire({
                title: 'Please contact the store with the Product name and quantity you would like to purchase',
                html: "<a href="+link+">Send Email</a>" + '<br/>' + '<br/>' + "<strong>Product Name: </strong>" + FilteredProducts[index].productName + '<br/>' + "<strong>Store Name: </strong>" + FilteredProducts[index].storeDetails.storeName + '<br/>' + "<strong>Contact Email: </strong>" + FilteredProducts[index].storeDetails.contactEmail + '<br/>'  + "<strong>Contact Number: </strong>" + FilteredProducts[index].storeDetails.contactNumber + '<br/>' + "<strong>Price: </strong>R" + FilteredProducts[index].productPrice         
            })
        }
    }

    //stop people from naviagting without being logged in
    function CheckLogin(location)
    {
        if(currentUser == " " || currentUser == null){
            Swal.fire(
                'Oops',
                'Looks like you arent logged in, please log in to view product details.',
                'warning'
            )
        }
        else{
            if(location === "/Userstore"){
                navigate("/Userstore")
            }
            else{
                navigate("/Product")
            }
        }
    }

    //this function makes the page scroll to the top on load
    function ScrollToTop(){
        window.scrollTo({
          top: 0,
          behavior: 'instant',
        })
    }
    

    //this method is solely to allow the search functionality to be fired by the enter key
    /*try{
        let searchInput = document.getElementById('M_SA_SearchTerm');
        searchInput.addEventListener("keypress", function(event){
            if(event.key === "Enter"){
                event.preventDefault();
                document.getElementById("Search_Btn").click();
            }
        });
    }
    catch(error){

    }*/

    function expandFilters(){
        let FilterWrap = document.getElementById("CW_Wrap");
        if(FilterWrap.classList.contains("hidden")){
            FilterWrap.classList.remove("hidden");
        }
        else{
            FilterWrap.classList.add("hidden")
        }
    }
        

    //used to fire api call as soon as the page loads
    useEffect(() => {
        ScrollToTop();
        GetProducts();
        GetIndustrySec();
    }, []);


    return(
        <div id="Collection_Wrap">
            <div className="Collection_Search_Wrap">
                <input value={SearchTerm} 
                onChange={(event) => { setSearchTerm(event.target.value);}} id="M_SA_SearchTerm" className="M_SA_SearchTerm" type="text" placeholder="Search..." required />
                <button id="Search_Btn" className="BtnGrey" onClick={() => {FireSearchLog(); Search(); }}>Search</button>
                <a onClick={() => {expandFilters()}}><FontAwesomeIcon icon={faFilter}/></a>
            </div>
            <div className="Collection_Wrap">
                <div id="CW_Wrap" className="Collection_Filters">
                    <h3>Fiters:</h3>
                    <div>
                        <label for="IndustrySec_Filter">Industry Sectors:</label>
                        <select name="IndustrySec_Filter" id="IndustrySec_Filter" /*onChange={(e)=> {selectedFilter = e.target.value; Event = "Filter"; updateFilter(selectedFilter, selectedOrder, Event);
                            }}*/ > 
                            <option value="0" selected>Industry Sectors:</option>
                            {IndustrySec.map((item, index) => (
                                <option value={item.industrySectorID}>
                                    {item.industrySectorName}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label for="Order_Filter">Order By:</label>
                        <select name="Order_Filter" id="Order_Filter" /*onChange={(e)=> {selectedOrder = e.target.value; Event = "Order"; updateFilter(selectedFilter, selectedOrder, Event);}}*/ > 
                            <option value="0" selected>Order By:</option>
                            <option value="1" >Price High - Low</option>
                            <option value="2" >Price Low - High</option>
                            <option value="3" >DateCreated (Old - New)</option>
                            <option value="4" >DateCreated (New - Old)</option>
                        </select>
                    </div>
                    <button className="BtnGrey" onClick={(e)=>{selectedFilter = document.getElementById("IndustrySec_Filter").value; selectedOrder = document.getElementById("Order_Filter").value; updateFilter(selectedFilter, selectedOrder)}}>Apply Filters</button>
                </div>
                <div className="Collection_Prod_Wrap">
                    <div>
                        {FilteredProducts.length == 0 ?
                            <div>
                                <div className="No_Results">
                                    <h3>Sorry it seems that we currently have no products that match your search.</h3>
                                    <h3>We have an incredicle portal available that allows users to create RFQs for whatever they may need.</h3>
                                    <h3>Get in touch with our incredible team <a href={'mailto:rfq@scnet.co.za?&subject=Creation%20Of%20New%20RFQ&body=Please%20assist%20me%20in%20creating%20a%20RFQ%20for%20:%0D%0AFullName:%0D%0AContact%20Number:%0D%0ACompany%20Name:'}>HERE</a> and they would be happy to help you.</h3>
                                </div>    
                                
                            </div>    
                        :
                            <div>
                                {FilteredProducts.map((item, index) => (
                                    <div key={index} id="Prod_Card">
                                        <div onClick={()=>{
                                            dispatch(CurrentProduct(item));}} className="Prod_Card box">
                                            {date.getTime() >= Date.parse(item.startDate) && Date.parse(item.endDate) >= date.getTime() && item.comparePrice > 0 ?
                                                <div class="ribbon"><span>SALE</span></div>
                                                :
                                                <div></div>
                                            }
                                            <div className="Prod_Card_Img" onClick={()=>{ location = "/Product"; CheckLogin(location)}}>
                                                { item.imagesUrls != "" ?
                                                    <LazyLoadImage src={item.imagesUrls[0]} width="150px" height="150px" alt='ProductFeatureImage'/>
                                                    :
                                                    <div>
                                                        { item.companyLogoUrl != "" ?
                                                            <LazyLoadImage src={item.companyLogoUrl} width="150px" height="150px" alt='CompanyLogo'/>
                                                            :
                                                            <LazyLoadImage src={SCNetLogo} width="150px" height="150px"/>
                                                        }
                                                    </div>
                                                }
                                                
                                            </div>    
                                            <div className="Prod_Card_Text">
                                                <h4 onClick={()=>{ location = "/Product";
                                                CheckLogin(location)}}>{item.productName}</h4>
                                                <p onClick={()=>{ location = "/Product";
                                                CheckLogin(location)}}><strong>R</strong> {item.productPrice} 
                                                {date.getTime() >= Date.parse(item.startDate) && Date.parse(item.endDate) >= date.getTime() && item.comparePrice > 0 ?
                                                    <a> Price: R{item.comparePrice}</a>
                                                :
                                                    <a></a>
                                                }</p>
                                                <p className="Prod_Card_Desc" onClick={()=>{ location = "/Product";
                                                CheckLogin(location)}}>{item.productDescription}</p>
                                                <p>Sold By: <a className="CollectionSoldBy" onClick={()=>{
                                                    dispatch(CurrentProduct(item));location = "/Userstore"; CheckLogin(location)}}>{item.storeDetails.storeName}</a></p>
                                            </div>
                                            <div className="Prod_Card_Btns">
                                                <button className="BtnGrey Prod_Card_BN" onClick={() => { dispatch(CurrentProduct(item)); fireMessage(index); }}>
                                                    View Details
                                                </button>
                                                {isOpenMessage && <CollectionMessagePop MessageIsOpen={MessageIsOpen}/>}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>  
                        }
                              
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Collection;