import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';


export default props => {

  let dispatch = useDispatch();
   let navigate = useNavigate();


  return (
    <Menu>
      <div>
        <a className="menu-item" onClick={() => {navigate("/")}}>
          Home
        </a>
        <a className="menu-item" onClick={() => {navigate("/Collection")}}>
          Products
        </a>
        <a className="menu-item" onClick={() => {navigate("/AboutUs")}}>
          About Us
        </a>
        <a className="menu-item" onClick={() => {navigate("/ContactUs")}}>
          Contact Us
        </a>
        <a className="menu-item" onClick={() => {navigate("/CompanyIndex")}}>
          Index
        </a>
      </div>    
    </Menu>
  );
};