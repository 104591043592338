import './Manager.scss';
import {React,useState, useEffect} from "react";
import { RiCloseLine } from "react-icons/ri";
import Api from '../../AxiosRest/AxiosRest';
import Swal from 'sweetalert2';


//State imports
import { useSelector } from 'react-redux';

const CreateUserModel = ({ MCreateUserIsOpen }) => {

    const [role, setRole] = useState('');
    const [email, setEmail] = useState('');
    const [surname, setSurname] = useState('');
    const [firstName, setFirstName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    let NewUser = [];
    let CurrentUser = [];
    let Roles = [];

    //fetching required data from redux
    CurrentUser = useSelector(state => state.CurrentUser);
    Roles = useSelector(state => state.AllRoles);


    function CreateAdminUser(){
        if(password === confirmPassword){
            NewUser = {
                firstName: firstName,
                lastName: surname,
                email: email,
                roleID: role,
                password: password
            }
            Api.post('/store/Manager/CreateNewAdminUser',  NewUser)
              .then((response) => {
                Swal.fire(
                  'Great!',
                  'New User has been created!',
                  'success'
                )
            });
            MCreateUserIsOpen(false);
        }
        else{
          Swal.fire(
            'Error',
            'Please ensure all the fields are filled in correctly!',
            'error'
          )
        }
    }

    function ValidatePassword(){
        if(password !== confirmPassword){
            document.getElementById("M_Create_ConfirmPassword").style.border = "1px solid #CC171E";
        }
        else{
            document.getElementById("M_Create_ConfirmPassword").style.border = "1px solid #333";
        }
    }

    //used to fire api call as soon as the page loads
    useEffect(() => {
        
    }, []);

  
    return (
      <>
        <div className="darkBG" onClick={() => MCreateUserIsOpen(false)} />
        <div id="M_Create_User" className="M_CreateCentered" >
          <div className="Modal">
            <div className="modalHeader">
              <h3 className="heading">Create New User</h3>
            </div>
            <button className="closeBtn" onClick={() => MCreateUserIsOpen(false)}>
              <RiCloseLine style={{ marginBottom: "-3px" }} />
            </button>
            <div className="modalContent">
                <div className="M_Create_Info_Wrap">
                    <div>
                        <label for="firstname" className="lbl_M_Create_firstname">First Name:</label> 
                        <input id="M_Create_firstname" name="Create_firstname" className="M_Create_firstname" type="text" onChange={(event) => {
                        setFirstName(event.target.value);
                        }} placeholder="First Name" required />
                    </div>
                    <div>
                        <label for="surname" className="lbl_M_Create_surname">Surname:</label> 
                        <input id="M_Create_surname" name="surname" className="M_Create_surname" type="email" onChange={(event) => {
                        setSurname(event.target.value);
                        }} placeholder="Surname" required />
                    </div>
                    <div>
                        <label for="email" className="lbl_M_Create_email">Email:</label> 
                        <input id="M_Create_email" name="email" className="M_Create_email" type="text" onChange={(event) => {
                        setEmail(event.target.value);
                        }} placeholder="Email" required />
                    </div>
                    <div>
                        <label for="password" className="lbl_M_Create_password">Password:</label> 
                        <input id="M_Create_password" name="password" className="M_Create_password" type="password" onChange={(event) => {
                        setPassword(event.target.value);
                        }} placeholder="password" required />
                    </div>
                    <div>
                        <label for="ConfirmPassword" className="lbl_M_Create_ConfirmPassword">Confirm Password:</label> 
                        <input id="M_Create_ConfirmPassword" name="password" className="M_Create_ConfirmPassword" type="password"
                        onBlur={(event) => {
                            ValidatePassword();
                        }} onChange={(event) => {
                        setConfirmPassword(event.target.value);
                        }} placeholder="Confirm Password" required />
                    </div>
                    <div>
                        <label for="Role" className="lbl_M_Create_Role">Role:</label> 
                        <select name="Create_DropDown_Role" id="M_Create_DropDown_Role" onChange={(e)=> {setRole(e.target.value)}}> 
                            <option value="0" selected>Role:</option>
                            {Roles.map((item, index) => (
                                <option value={item.roleID}>
                                    {item.roleDescription}
                                </option> 
                            ))}
                        </select>
                    </div>
                </div>
            </div>
            <div className="modalActions">
              <div className="actionsContainer">
                <button className="BtnGrey M_Create_Update" onClick={() => CreateAdminUser()}>
                    Create User
                </button>
                <button className="LogincancelBtn BtnLightGrey" onClick={() =>    MCreateUserIsOpen(false)}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

export default CreateUserModel;
