import loggedReducer from "./isLoggedIn";
import CurrentUser from "./CurrentUser";
import ProductReducer from "./ProductReducer";
import CartReducer from "./CartReducer";
import CurrentProduct from "./CurrentProduct";
import FilteredProducts from "./FilteredProducts";
import ViewedUser from "./ViewedUser";
import SelectedMenu from "./SelectedMenu";
import AllStores from "./AllStores";
import FilteredStores from "./FilteredStores";
import AllUsers from "./AllUsers";
import AllRoles from "./AllRoles";
import SelectedStore from "./SelectedStore";
import { combineReducers } from "redux";

const allReducers = combineReducers({
    Login : loggedReducer,
    CurrentUser: CurrentUser,
    Product: ProductReducer,
    CartReducer: CartReducer,
    CurrentProduct: CurrentProduct,
    FilteredProduct: FilteredProducts,
    ViewedUser: ViewedUser,
    SelectedMenu: SelectedMenu,
    AllStores: AllStores,
    FilteredStores: FilteredStores,
    AllUsers: AllUsers,
    AllRoles: AllRoles,
    SelectedStore: SelectedStore
})

export default allReducers;

