import './Manager.scss';
import {React,useState, useEffect} from "react";
import { RiCloseLine } from "react-icons/ri";
import Api from '../../AxiosRest/AxiosRest';

//State imports
import { useSelector } from 'react-redux';

const DeleteUserModel = ({ MDeleteUserIsOpen }) => {

    const [role, setRole] = useState('');
    const [email, setEmail] = useState('');
    const [surname, setSurname] = useState('');
    const [firstName, setFirstName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    let NewUser = [];
    let CurrentUser = [];
    let Roles = [];

    //fetching required data from redux
    CurrentUser = useSelector(state => state.CurrentUser);
    Roles = useSelector(state => state.AllRoles);


    function CreateAdminUser(){
        
    }

    //used to fire api call as soon as the page loads
    useEffect(() => {
        
    }, []);

  
    return (
      <>
        <div className="darkBG" onClick={() => MDeleteUserIsOpen(false)} />
        <div id="M_Create_User" className="M_DeleteCentered" >
          <div className="Modal">
            <div className="modalHeader">
              <h3 className="heading">Delete User</h3>
            </div>
            <button className="closeBtn" onClick={() => MDeleteUserIsOpen(false)}>
              <RiCloseLine style={{ marginBottom: "-3px" }} />
            </button>
            <div className="modalContent">
                    <h2>Are you sure you would like to delete</h2>
                    <h3>{CurrentUser.firstName} {CurrentUser.lastName}</h3>
            </div>
            <div className="modalActions">
              <div className="actionsContainer">
                <button className="BtnGrey M_Create_Update" onClick={() => MDeleteUserIsOpen(false)}>
                    Delete User
                </button>
                <button className="LogincancelBtn BtnLightGrey" onClick={() => MDeleteUserIsOpen(false)}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

export default DeleteUserModel;
