const initialState = {
  cart: []
};

const AddToCart = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_TO_CART':
      state.cart.map((item, index) => {
        if(item.product.productID === action.payload.product.productID){
            const updatedCartItems = [...state.cart];
            updatedCartItems[index] = {
            ...updatedCartItems[index],
            CartQuantity: updatedCartItems[index].CartQuantity + 1
            };
            let CartItems = {...state, cart: updatedCartItems}
            window.localStorage.setItem('Cart', JSON.stringify(CartItems));
            return {
            ...state,
            cart: updatedCartItems
            };
        }
         else {
          let product = action.payload;
          let obj = {product,CartQuantity: 1};
          let newobj = {...state, cart: [...state.cart, obj]};

          let CartItems = {...state, cart: [...state.cart, obj]}
          window.localStorage.setItem('Cart', JSON.stringify(CartItems));
          return {     
          ...state,
          cart: [...state.cart, obj]
          };
      }
    })
     // const existingItemIndex = state.cart.findIndex(item => item.product.productID === action.payload.productID);
      
    case 'REMOVE_FROM_CART':
      state.cart.map((item, index) => {
        if(item.product.productID === action.payload.product.productID){
          if(item.CartQuantity > 1){
            const updatedCartItems = [...state.cart];
            updatedCartItems[index] = {
            ...updatedCartItems[index],
            CartQuantity: updatedCartItems[index].CartQuantity - 1
            };
            let CartItems = {...state, cart: updatedCartItems}
            window.localStorage.setItem('Cart', JSON.stringify(CartItems));
            console.log(CartItems);
            return {
            ...state,
            cart: [updatedCartItems]
            };    
          }
          else{
            let CartItems = {...state, cart: state.cart.filter(item.product.productID !== action.payload.product.productID)};
            window.localStorage.setItem('Cart', JSON.stringify(CartItems));
            return {
              ...state,
              cart: state.cart.filter(
                item.product.productID !== action.payload.product.productID
              ),
            };
          }
        }
      })      
    default:
      return state;
  }
};

export default AddToCart;