import '../Manager/Manager.scss';
import Api from '../../AxiosRest/AxiosRest';
import {React,useState, useEffect} from "react";
import EditUserModel from './EditUserModel';
import CreateUserModel from './CreateUserModel';
import DeleteManagerModel from './DeleteManagerModel';

//State imports
import { useSelector, useDispatch } from 'react-redux';
import { CurrentUser, FilterStores, AllUsers, AllRoles } from '../../Actions';

function ManageUsers(){

    const [SearchTerm, setSearchTerm] = useState("");
    const [isOpenEditUser, EditUserIsOpen] = useState(false);
    const [isOpenMDeleteUser, MDeleteUserIsOpen] = useState(false);
    const [isOpenMCreateUser, MCreateUserIsOpen] = useState(false);
    let dispatch = useDispatch();
    let allUsers = [];
    let allRoles = [];

    //fetching required data from redux
    allUsers = useSelector(state => state.AllUsers);
    allRoles = useSelector(state => state.AllRoles);


    //Firing API call to recieve full list of Users
    let GetAllUsers = async() =>{
        try{
            await Api.get('/store/Manager/GetAllUsers').then((response) => {
                dispatch(AllUsers(response.data));
            });
        }
        catch(exception){   
            
        }  
    }

    let GetAllRoles = async() =>{
        try{
            await Api.get('/store/Manager/GetAdminRoles').then((response) => {
                dispatch(AllRoles(response.data));            
            });
        }
        catch(exception){   
            
        }  
    }


    function Search(){
        try{
            if(SearchTerm != ""){
                dispatch(FilterStores(allUsers.filter((item) => item.storeName.includes(SearchTerm))));
            }
            else{
                dispatch(FilterStores(""));
            } 
        }
        catch(error){
            
        }   
    }

    function ResetFilter(){
        dispatch(FilterStores(""));
    }

    function ScrollToTop(){
        window.scrollTo({
          top: 0,
          behavior: 'instant',
        })
      }


    //used to fire api call as soon as the page loads
    useEffect(() => {
        ScrollToTop();
        GetAllUsers();
        GetAllRoles();
        ResetFilter();
        Search();
    }, []);

    return(
        <div id="ManageUser">
            <div>
                <div className="M_MU_Search_Wrap">
                    <div className="M_MU_Search">
                        <input value={SearchTerm} 
                        onChange={(event) => { setSearchTerm(event.target.value);}} className="M_MU_SearchTerm" type="text" placeholder="Store Name" required />
                        <button className="BtnGrey" onClick={() => {Search()}}>Search</button>
                    </div>
                    <div>
                        <button className="BtnGrey" onClick={() => {MCreateUserIsOpen(true);}}>Create User</button>
                        {isOpenMCreateUser && <CreateUserModel MCreateUserIsOpen={MCreateUserIsOpen}/>}
                    </div>
                </div>
                <div className="M_MU_Users">
                    {!allUsers ?
                        <div>
                            <h2>There are currently no users to display</h2>
                        </div>
                        :
                        <div>
                            {allUsers.map((item, index) => (
                            <div key={index} className="M_MU_User_Outer_Wrap">
                                <div className="M_MU_User_Wrap">
                                    <div className="M_MU_User_Col1">
                                        <div className="M_MU_User_About">
                                            <h2>{item.firstName} {item.lastName}</h2>
                                        </div>
                                        <div className="M_MU_User_Desc">
                                            <p>{item.email}</p>
                                        </div>            
                                    </div>
                                    <div className="M_MU_User_Col2">
                                        <div className="M_MU_User_Btn">
                                            <button className="M_MU_OpenFeatured" onClick={() => {EditUserIsOpen(true); dispatch(CurrentUser(item));}}>
                                                Edit
                                            </button>
                                            {isOpenEditUser && <EditUserModel EditUserIsOpen={EditUserIsOpen}/>}
                                            <button className="M_MU_OpenFeatured" onClick={() => {MDeleteUserIsOpen(true);}}>
                                                Delete
                                            </button>
                                            {isOpenMDeleteUser && <DeleteManagerModel MDeleteUserIsOpen={MDeleteUserIsOpen}/>}
                                        </div>            
                                    </div>
                                </div>
                            </div>
                            ))}
                        </div>
                    }     
                </div>
            </div>
        </div>
    )
}

export default ManageUsers;