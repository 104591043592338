import './Manager.scss';
import {React,useState, useEffect} from "react";
import { RiCloseLine } from "react-icons/ri";
import Api from '../../AxiosRest/AxiosRest';
import Swal from 'sweetalert2';

//State imports
import { useSelector, useDispatch } from 'react-redux';
import { AllStores } from '../../Actions';

const RejectStoreModel = ({ MRejectStoreIsOpen }) => {

  let dispatch = useDispatch();

  const [reason, setReason] = useState('');
  let CurrentUser = [];
  let SelectedStore = [];
  let CatalogID;
  let AdminID;
  let StatusDescription;
  let Roles = [];
  let AllStore = [];

  //fetching required data from redux
  CurrentUser = useSelector(state => state.CurrentUser);
  Roles = useSelector(state => state.AllRoles);
  SelectedStore = useSelector(state => state.SelectedStore);
  AllStore = useSelector(state => state.AllStores);


  function RejectStore(){
    StatusDescription = "Declined";

    let RejectedStore = {
        CatalogID: SelectedStore.catalogID,
        adminID: CurrentUser.adminID,
        statusName: StatusDescription,
        reason: reason
    }

    Api.post('/store/Stores/ApproveDeclineStore',  RejectedStore)
      .then((response) => {  
        Swal.fire(
          'Oh No :(',
          'This store has been successfully rejected!',
          'success'
        )
        Object.keys(AllStore).forEach((item) => {
          if(AllStore[item].catalogID == SelectedStore.catalogID) {
            AllStore[item].isApproved = 3;
            AllStore[item].statusDescription = "Rejected";
          }
        })
        dispatch(AllStores(AllStore));
        MRejectStoreIsOpen(false); 
    })
    .catch((response) => {
      Swal.fire(
        'Oops',
        'There was an error rejecting this store',
        'error'
      )
    })
    
  }

  //used to fire api call as soon as the page loads
  useEffect(() => {
      
  }, []);


  return (
    <>
      <div className="darkBG" onClick={() => MRejectStoreIsOpen(false)} />
      <div id="M_Create_User" className="M_RejectCentered" >
        <div className="Modal">
          <div className="modalHeader">
            <h3 className="heading">Reject Store</h3>
          </div>
          <button className="closeBtn" onClick={() => MRejectStoreIsOpen(false)}>
            <RiCloseLine style={{ marginBottom: "-3px" }} />
          </button>
          <div className="modalContent">
              <div className="M_Approve_Store_Wrap">
                  <label for="M_Reject_Reason">Rejection Reason</label>
                  <textarea name="M_Reject_Reason" className="M_Reject_Reason" rows="4" cols="50" required onChange={(event) => { setReason(event.target.value);}}></textarea>
              </div>
          </div>
          <div className="modalActions">
            <div className="actionsContainer">
              <button className="BtnGrey M_Approve_Update" type='submit' onClick={() => RejectStore()}>
                  Reject Store
              </button>
              <button className="LogincancelBtn BtnLightGrey" onClick={() => MRejectStoreIsOpen(false)}>
                  Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RejectStoreModel;
