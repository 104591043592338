import {React,useState, useEffect} from "react";
import Api from '../../AxiosRest/AxiosRest';
import Swal from 'sweetalert2';

function SearchStats(){

    let [SearchData, setSearchData] = useState([]);

    let Search = async (start, end) => {
        //start.setHours(0);
        //start.setMinutes(1);
        //end.setMinutes(59);
        //end.setHours(23);

        try{
            Swal.fire({
                title: 'Loading',
                html: '<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>',
                showCancelButton: false,
                showConfirmButton: false,
                allowOutsideClick: false,
                didOpen: () => {
                  Swal.showLoading();
                }
            });
            
            Api
            .post('/store/StoreStats/ReadSearchTermsData', {StartDate: start, EndDate: end})
            .then((response) => {
                Swal.close();
                let obj = response.data;
                setSearchData(response.data);
                let body = document.getElementById('SS-tbody');
                for(var i = 0; i < obj.lenght; i++){
                    var tr = "<tr>";
                    tr += "<td>" + obj[i].searchterm + "</td>" + "<td>" + obj[i].searchdate + "</td>" + "<td>" + obj[i].numresponses + "</td>" + "<td>" + obj[i].tradingname + "</td>" + "</tr>";
                    body.innerHTML += tr;
                }
            })
            .catch((response) => {
            Swal.close();
            Swal.fire(
                'Error!',
                'There was an error fetching the search information',
                'error'
            )
            });
        }
        catch{

        }
    }

    return(
        <div id="SearchStats">
            <div className="SS-Row-1">
                <div className="SS-Inputs">
                    <div>
                        <label>Start Date:</label>
                        <input id="SS-StartDate" type="date"></input>
                    </div>
                    <div>
                        <label>End Date:</label>
                        <input id="SS-EndDate" type="date"></input>
                    </div>
                    <div>
                        <button className="BtnGrey" onClick={() => {
                            let start = document.getElementById("SS-StartDate").value;
                            let end = document.getElementById("SS-EndDate").value;
                            Search(start, end);}}
                            >Search
                        </button>
                    </div>
                </div>               
            </div>
            <div className="SS-Row-2">
                {SearchData.lenght == 0 ? 
                    <div>
                        Please select a Start Date and End Date.
                    </div>
                    :
                    <table>
                        <tbody>
                            <tr>
                                <th>Search Term</th>
                                <th>Date Searched</th>
                                <th>Store Name</th>
                                <th>Number Of Results</th>
                            </tr>
                            {SearchData.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.searchTerm}</td>
                                    <td>{item.dateSearched}</td>
                                    <td>{item.storeName}</td>
                                    <td>{item.numberOfResults}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                }
            </div>
        </div>
    )
}

export default SearchStats;