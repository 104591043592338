import "../Account/Accounts.scss"
import React, { useState, useEffect } from 'react';
import Api from "../../AxiosRest/AxiosRest";
import Swal from 'sweetalert2';
import Tooltip from "rc-tooltip";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import SCNetTreeLogo from '../../Images/SCNetTreeLogo.png';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faRectangleXmark} from '@fortawesome/free-solid-svg-icons'

//State imports
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router";
import { CurrentProduct, CurrentUser } from "../../Actions";

const MAX_COUNT = 5;

function CreateProduct() {

    //recieving held User in state
    let User = useSelector(state => state.CurrentUser);
    let product = useSelector(state => state.CurrentProduct);

    //Navigate class initialize
    let navigate = useNavigate();

    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [previewImages, setPreviewImages] = useState([]);
    const [fileLimit, setFileLimit] = useState(false);
    const [ProductTitle, setProductTitle] = useState("");
    const [ProductDesc,setProductDesc] = useState("");
    const [Price, setPrice] = useState("");
    const [Stock, setStock] = useState("");
    let [IndustrySec, setIndustrySec] = useState([]);
    const [DealPrice, setDealPrice] = useState("");
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    let [SelectedIndustry, setSelectedIndustry] = useState("");

    let NewProduct = new FormData();

    let CreateProductObject = () => {

        startDate.setHours(0);
        startDate.setMinutes(1);
        endDate.setMinutes(59);
        endDate.setHours(23);

        //Building Form Data object to send to API
        if(uploadedFiles == ""){
            let file = new File([""], SCNetTreeLogo);
            NewProduct.append("Images", file);
        }else{
            for (const image of uploadedFiles){
                NewProduct.append("Images", image);
            }
        }

        if(User.roleID != undefined ){
            NewProduct.append("CatalogID", product.catalogID);
        }
        else{
            NewProduct.append("UserID", User.id);
        }

        if(ProductTitle == ""){
            Swal.fire(
                'Oops!',
                'Please ensure you have entered a Product Title)',
                'error'
            )
            return;
        }else{
            NewProduct.append("ProductName", ProductTitle);
        }

        if(ProductDesc == ""){
            NewProduct.append("ProductDescription", "N/A");
        }else{
            NewProduct.append("ProductDescription", ProductDesc);
        } 

        if(Price == ""){
            Swal.fire(
                'Oops!',
                'Please ensure you have entered a Price)',
                'error'
            )
            return;
        }else{
            NewProduct.append("Price", Price);
        }

        if(Stock == ""){
            Swal.fire(
                'Oops!',
                'Please ensure you have entered a Quantity)',
                'error'
            )
            return;
        }else{
            NewProduct.append("Quantity", Stock);
        }

        if(SelectedIndustry == ""){
            Swal.fire(
                'Oops!',
                'Please ensure you have entered a Industry Sector)',
                'error'
            )
            return;
        }else{
            NewProduct.append("IndustrySectorID", SelectedIndustry);
        }

        if(DealPrice != ""){
            NewProduct.append("comparePrice", DealPrice);
        }
        else{
            NewProduct.append("comparePrice", 0);
        }

        NewProduct.append('startDate', startDate.toISOString());
        NewProduct.append('endDate', endDate.toISOString());

        if(User.roleID != undefined){
            MCreateProduct();//manager is creating the product
        }
        else{
            CreateProduct();// a user is doing the call
        }
    }

    //Firing API call to recieve full list of Industry Sectors
    let GetIndustrySec = async() =>{
        try{
            await Api.get('/store/Products/GetIndustrySectors').then((response) => {
                setIndustrySec(response.data.industrySectors);
                
            });
        }
        catch(exception){   
            
        }  
    }

    //Validation functions


    //Posting the data to the API using AXIOS Post request
    let MCreateProduct = async () => {
        if(startDate.getTime() <= endDate.getTime()){//checking the dates are not in the incorrect format
            Swal.fire({
                title: 'Loading',
                html: '<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>',
                showCancelButton: false,
                showConfirmButton: false,
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading();
                }
            });
            Api.postForm('/store/Manager/CreateProduct', NewProduct,{
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((response) => {
                Swal.close();
                Swal.fire(
                    'Great!',
                    'Your product has been created',
                    'success'
                )
            })
            .catch((response) => {
                Swal.fire(
                    'error',
                    'We encountered an error',
                    'error'
                )
            })
        }
        else{
            Swal.fire(
                'Error',
                'Please ensure The start date is before the end date if you intend to create a promotion',
                'error'
            )
        }
    }

    
    //Posting the data to the API using AXIOS Post request
    let CreateProduct = async () => {
        if(startDate.getTime() <= endDate.getTime()){//checking the dates are not in the incorrect format
            Swal.fire({
                title: 'Loading',
                html: '<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>',
                showCancelButton: false,
                showConfirmButton: false,
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading();
                }
            });
            Api.postForm('/store/Products/AddProduct', NewProduct,{
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((response) => {
                Swal.close();
                Swal.fire(
                    'Great!',
                    'Your product has been created',
                    'success'
                )
                navigate("/Account");//add if statement for manager
            })
            .catch((response) => {
                Swal.fire(
                    'error',
                    'We encountered an error',
                    'error'
                )
            })
        }
        else{
            Swal.fire(
                'Error',
                'Please ensure The start date is before the end date if you intend to create a promotion',
                'error'
            )
        }
    }


    //Handling the changing of multiple input files and sorting them into an array that I will later filter through and send to the API
    const handleUploadFiles = files => {
        const uploaded = [...uploadedFiles];
        let limitExceeded = false;
        files.some((file) => {
            if(uploaded.findIndex((f) => f.name === file.name) === -1){
                uploaded.push(file);
                if(uploaded.length === MAX_COUNT) setFileLimit(true);
                if(uploaded.length > MAX_COUNT){
                    alert('You can only add a maximum of 5 files');
                    setFileLimit(false);
                    limitExceeded = true;
                    return true;
                }
            }
        })
        if (!limitExceeded) setUploadedFiles(uploaded)

    }

    const handleFileEvent = (e) => {
        const chosenFiles = Array.prototype.slice.call(e.target.files)
        handleUploadFiles(chosenFiles);
  
    }

    const displayPreview = (e) => {
        const FileUrl = URL.createObjectURL(e.target.files[0]);
        setPreviewImages(current => [...current, FileUrl]);
    }

    const deleteImage = (index) => {
        setPreviewImages(previewImages.slice(0, index).concat(previewImages.slice(index+1)));
        setUploadedFiles(uploadedFiles.slice(0, index).concat(uploadedFiles.slice(index+1)));
        setFileLimit(false);
    }

    function ScrollToTop(){
        window.scrollTo({
          top: 0,
          behavior: 'instant',
        })
    }

    function DetectUser(){
        if(User == " "){
            navigate("/");
        }
    }

    useEffect(() => {
        DetectUser();
        ScrollToTop();
        GetIndustrySec();    
    }, []);


    return(
        <div id="CreateProd_Outer">
            <div id="CreateProd">
                <div className="CreateProd_Wrap">
                    <div>
                        <Tooltip placement="left" overlay={<span>Upload your product images here. Maximum of 5 allowed</span>}>
                            <label for="ProdImages">Image</label>
                        </Tooltip>    
                        <input type="file" name="ProdTitle" id="ProdImage" onChange={(e) => {handleFileEvent(e); displayPreview(e);}} accept="image/png, image/gif, image/jpeg" disabled={fileLimit}></input>
                    </div>
                    <div>
                        <label for="ProdTitle">Product Title</label>
                        <input value={ProductTitle} type="text" name="ProdTitle" id="ProdTitle" onChange={(event) => {setProductTitle(event.target.value);}}></input>
                    </div>
                    <div>
                        <label for="ProdDesc">Description</label>
                        <textarea value={ProductDesc} type="textarea" name="ProdDesc" id="ProdDesc" rows="4" cols="50" onChange={(event) => {setProductDesc(event.target.value);}}></textarea>
                    </div>
                    <div>
                        <label for="ProdStock">Stock</label>
                        <input value={Stock} type="number" name="ProdStock" id="ProdStock" onChange={(event) => {setStock(event.target.value);}}></input>
                    </div>
                    <div>
                        <label for="IndustrySec">Industry Sector</label>
                        <select type="text" name="IndustrySec" id="IndustrySec" onChange={(e)=> {setSelectedIndustry(e.target.value)}}>
                            <option value="0" selected>Industry Sectors:</option>
                            {IndustrySec.map((item, index) => (
                                <option value={item.industrySectorID}>
                                    {item.industrySectorName}
                                </option>
                            ))}        
                        </select>
                    </div>
                    <div className="Create_Prices_Wrap">
                        <div>
                            <Tooltip placement="top" overlay={<span>This is the base price of your product</span>}>
                                <label for="Create_Price" className="lblCreate_Price">Price:</label> 
                            </Tooltip>
                            <input value={Price} type="number" name="ProdPrice" id="ProdPrice" placeholder="R" onChange={(event) => {setPrice(event.target.value);}}></input>
                        </div>
                        <div>
                            <Tooltip placement="top" overlay={<span>This is the promotional price. Setting this value will cause your product to appear on sale</span>}>
                                <label for="Create_DealPrice" className="lblCreate_DealPrice">Deal Price:</label>
                            </Tooltip>     
                            <input id="Create_DealPrice" name="Create_DealPrice" className="Create_DealPrice" type="number" onChange={(event) => {
                            setDealPrice(event.target.value)
                            }} placeholder="R" required />
                        </div>
                    </div>
                    <div className="Create_PromoDates_Wrap">
                        <div>
                            <Tooltip placement="left" overlay={<span>Starting date of this products promotion, if applicable.</span>}>
                                <label for="Create_StartDate" className="lblCreate_StartDate">Start Date:</label> 
                            </Tooltip>
                            <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                        </div>
                        <div>
                            <Tooltip placement="right" overlay={<span>Ending date of this products promotion, if applicable.</span>}>
                                <label for="Create_EndDate" className="lblCreate_EndDate">End Date:</label> 
                            </Tooltip>
                            <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
                        </div>
                    </div>
                </div>
                <div className="CreateProd_Btns">
                    <div className="CreateProd_CreateBtn">
                        <button className="BtnGrey" onClick={CreateProductObject}>Create Product</button>
                    </div>
                    <div className="CreateProd_CancelBtn">
                        <button className="BtnGrey" onClick={() => navigate(-1)}>Cancel</button>
                    </div>
                </div>           
            </div>
            <div id="Preview_Wrapper">
                <h3>Images</h3>
                {previewImages ?
                    <div className="PI_Outer">
                        {previewImages.map((item, index) => (
                            <div key={index} className="PI_Wrapper">
                                <div className="PI_Image_Wrapper">
                                    <img src={item} width="200px" height="150px" alt='PreviewImage'/>
                                    <div className="PI_Delete_Wrapper">
                                        <FontAwesomeIcon onClick={() => deleteImage(index)} icon={faRectangleXmark}/>
                                    </div>
                                </div>    
                                    
                            </div>    
                        ))}
                    </div>    
                    :
                    <div>

                    </div>    
                }
                
            </div>
        </div>
        
    )
}

export default CreateProduct;