import '../Footer/Footer.scss';
import instagram2 from '../../Images/instagram.png'
import facebook from '../../Images/facebook.png'
import Map from "../../Images/map.png"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faInstagram, faFacebookF, faTwitter} from '@fortawesome/free-brands-svg-icons'
import {faHouseChimney, faPhone, faCircleInfo, faStore} from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom';
import SCNetGrey from '../../Images/SCNetTreeLogo3.png';

function Footer(){

    let navigate = useNavigate();
    
    return(
        <div id="Footer">
            <div className="Footer_Wrap" style={{backgroundImage: `url(${Map})`}}>
                <div className="Footer_Col_1">
                    <h3>Quick Links</h3>
                    <div className="Nav_QuickLinks">
                        <div>
                            <div>
                                <a onClick={() => navigate("/")}><FontAwesomeIcon icon={faHouseChimney}/> Home</a>
                            </div>
                            <div>
                                <a onClick={() => navigate("AboutUs")}><FontAwesomeIcon icon={faCircleInfo}/>About Us</a>
                            </div>
                        </div>
                        <div>    
                            <div>
                                <a onClick={() => navigate("Collection")}><FontAwesomeIcon icon={faStore}/>Products</a>
                            </div>
                            <div>
                                <a onClick={() => navigate("ContactUs")}><FontAwesomeIcon icon={faPhone}/>Contact Us</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="Footer_Col_2">
                    <div>
                        <img src={SCNetGrey} width="400px"/>
                    </div>
                </div>
                <div className="Footer_Col_3">
                    <h3>Follow us!</h3>
                    <div>
                        <a href="https://www.instagram.com/scnet_za/" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faInstagram}/></a>
                        <a href="https://www.facebook.com/SupplyChainNetwork" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faFacebookF}/></a>
                        <a href="https://twitter.com/SCNet_ZA" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faTwitter}/></a>
                    </div>
                </div>
                
            </div>
        </div>
    );
}

export default Footer;