import './Manager.scss';
import {React,useState, useEffect} from "react";
import { RiCloseLine } from "react-icons/ri";
import Api from '../../AxiosRest/AxiosRest';
import Swal from 'sweetalert2';


//State imports
import { useSelector, useDispatch } from 'react-redux';
import { AllStores } from '../../Actions';

const ApproveStoreModel = ({ MApproveStoreIsOpen }) => {

  let dispatch = useDispatch();

  const [reason, setReason] = useState('');
  let CurrentUser = [];
  let SelectedStore = [];
  let StatusDescription;
  let Roles = [];
  let AllStore = [];

  //fetching required data from redux
  CurrentUser = useSelector(state => state.CurrentUser);
  Roles = useSelector(state => state.AllRoles);
  SelectedStore = useSelector(state => state.SelectedStore);
  AllStore = useSelector(state => state.AllStores);


  function ApproveStore(){

    StatusDescription = "Approved";

    let StoreApproved = {
      adminID: CurrentUser.adminID,
      CatalogID: SelectedStore.catalogID,
      statusName: StatusDescription,
      reason: " "
    }

    Api.post('/store/Stores/ApproveDeclineStore',  StoreApproved)
      .then((response) => {            
        Swal.fire(
          'Great!',
          'Store has been approved!',
          'success'
        )
        Object.keys(AllStore).forEach((item) => {
          if(AllStore[item].catalogID == SelectedStore.catalogID) {
            AllStore[item].isApproved = 2;
            AllStore[item].statusDescription = "Approved";
          }
        })
        dispatch(AllStores(AllStore)); 
        MApproveStoreIsOpen(false);
    })
    .catch((response)=>{
      Swal.fire(
        'Oops',
        'There was an issue approving this store',
        'error'
      )
    })
  }


  //used to fire api call as soon as the page loads
  useEffect(() => {
    
  }, []);


  return (
    <>
      <div className="darkBG" onClick={() => MApproveStoreIsOpen(false)} />
      <div id="M_Create_User" className="M_ApproveCentered" >
        <div className="Modal">
          <div className="modalHeader">
            <h3 className="heading">Approve Store</h3>
          </div>
          <button className="closeBtn" onClick={() => MApproveStoreIsOpen(false)}>
            <RiCloseLine style={{ marginBottom: "-3px" }} />
          </button>
          <div className="modalContent">
              <div className="M_Approve_Store_Wrap">
                  <h3>Are you sure you would like to approve this store</h3>
              </div>
          </div>
          <div className="modalActions">
            <div className="actionsContainer">
              <button className="BtnGrey M_Approve_Update" onClick={() => ApproveStore()}>
                  Approve Store
              </button>
              <button className="LogincancelBtn BtnLightGrey" onClick={() => MApproveStoreIsOpen(false)}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApproveStoreModel;
