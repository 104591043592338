import ContactUs from '../Components/Documentation/ContactUs';

function ContactUsPage () {
    return(
        <div>
            <ContactUs></ContactUs>
        </div>
    );
}

export default ContactUsPage;