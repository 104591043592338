import './Manager.scss';
import {React,useState, useEffect,useRef, startTransition} from "react";
import { RiCloseLine } from "react-icons/ri";
import Api from '../../AxiosRest/AxiosRest';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';


//State imports
import { useSelector, useDispatch } from 'react-redux';
import { Login, CurrentUser } from '../../Actions';

const EditUserModel = ({ EditUserIsOpen }) => {

    const dispatch = useDispatch();
    let navigate = useNavigate();
  
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [roleID, setRoleID] = useState('');
    const [password, setPassword] = useState('');
    const [checked, setChecked] = useState("");
    const [adminID, setAdminID] = useState("");
    let CurrentUser = [];
    let EditModal = [];
    let Roles = [];

    //fetching required data from redux
    CurrentUser = useSelector(state => state.CurrentUser);
    Roles = useSelector(state => state.AllRoles);

    function UpdateAdminUser(){   
      //if the user has selected edit info
      if(checked === "EditInfo"){
        let UpdatedUser = {
          firstName: firstName,
          lastName: lastName,
          email: email,
          roleID: roleID,
          adminID: adminID
        }

        //fire API call to the required endpoint with validation on adminID
        if(adminID !== null){
          Api.post('/store/Manager/UpdateAdminUser', 
            UpdatedUser
          )
          .then((response) => {
            Swal.fire(
              'Great!',
              'User info has been updated!',
              'success'
            )
          });
        }
      }

      //if the user has selected reset password
      if(checked === "Reset"){
        let UpdatedUser = {
          adminID: adminID,
          password: password
        }

        //fire API call to the required endpoint with validation on password
        if(password !== null && adminID !== null){
          Api.post('/store/Manager/ResetAdminPassword', 
            UpdatedUser
          )
          .then((response) => {
            Swal.fire(
              'Great!',
              'Password has been reset!',
              'success'
            )
          });
        }
      }
    }


    function HandleReset(){
        setChecked("Reset");
        EditModal = document.querySelectorAll('.M_EditCentered');
        EditModal.forEach(item => {
          item.style.height = "17rem";
        });
    }

    function HandleEdit(){
        setChecked("EditInfo");
        EditModal = document.querySelectorAll('.M_EditCentered');
        EditModal.forEach(item => {
          item.style.height = "28rem";
        });
    }

    function setDefaultValues(){
      setFirstName(CurrentUser.firstName);
      setLastName(CurrentUser.lastName);
      setEmail(CurrentUser.email);
      setRoleID(CurrentUser.roleID);
      setAdminID(CurrentUser.adminID);
    }

    useEffect(() => {
        setDefaultValues();
    },[]);
  
    return (
      <>
        <div className="darkBG" onClick={() => EditUserIsOpen(false)} />
        <div id="M_Edit_User" className="M_EditCentered" >
          <div className="Modal">
            <div className="modalHeader">
              <h3 className="heading">Edit User Details</h3>
            </div>
            <button className="closeBtn" onClick={() => EditUserIsOpen(false)}>
              <RiCloseLine style={{ marginBottom: "-3px" }} />
            </button>
            <div className="modalContent">
                <div className="M_Edit_Choices_Wrap">
                    <div className="M_Edit_ResetPassword_Wrap">
                        <button className="M_Edit_ResetPasswordBtn" value="Reset" onClick={() => HandleReset()}>Reset Password</button>
                    </div>
                    <div className="M_Edit_EditInfo_Wrap">
                        <button className="M_Edit_EditInfoBtn" value="EditInfo" onClick={() => HandleEdit()}>Edit Info</button>
                    </div>
                </div>
                
                <div className="M_EditPassword_Wrap" aria-hidden={checked !== "Reset" ? true : false}>
                    <div>
                        <label for="password" className="lbl_M_Edit_password">Reset Password:</label> 
                        <input id="M_Edit_password" name="Edit_password" className="M_Edit_password" type="text" onChange={(event) => {
                        setPassword(event.target.value);
                        }} placeholder="Password Reset" required />
                    </div>
                </div>

                <div className="M_Edit_Info_Wrap" aria-hidden={checked !== "EditInfo" ? true : false}>
                    <div>
                        <label for="username" className="lbl_M_Edit_username">Username:</label> 
                        <input id="M_Edit_username" name="username" className="M_Edit_username" type="text" defaultValue={CurrentUser.username} disabled="true" placeholder="Username"/>
                    </div>
                    <div>
                        <label for="firstname" className="lbl_M_Edit_firstname">First Name:</label> 
                        <input id="M_Edit_firstname" name="Edit_firstname" className="M_Edit_firstname" type="text" defaultValue={CurrentUser.firstName} onChange={(event) => {
                        setFirstName(event.target.value);
                        }} placeholder="First Name" required />
                    </div>
                    <div>
                        <label for="surname" className="lbl_M_Edit_surname">Surname:</label> 
                        <input id="M_Edit_surname" name="surname" className="M_Edit_surname" type="email" defaultValue={CurrentUser.lastName} onChange={(event) => {
                        setLastName(event.target.value);
                        }} placeholder="Surname" required />
                    </div>
                    <div>
                        <label for="email" className="lbl_M_Edit_email">Email:</label> 
                        <input id="M_Edit_email" name="email" className="M_Edit_email" type="text" defaultValue={CurrentUser.email} onChange={(event) => {
                        setEmail(event.target.value);
                        }} placeholder="Email" required />
                    </div>
                    <div>
                        <label for="Role" className="lbl_M_Edit_Role">Role:</label> 
                        <select name="Edit_DropDown_Role" id="M_Edit_DropDown_Role" onChange={(event)=> {setRoleID(event.target.value)}}> 
                            <option value={CurrentUser.roleID} selected>Current: {CurrentUser.roleDescription}</option>
                            {Roles.map((item, index) => (
                                <option value={item.roleID}>
                                    {item.roleDescription}
                                </option> 
                            ))}
                        </select>
                    </div>
                </div>
            </div>
            <div className="modalActions">
              <div className="actionsContainer">
                <button className="BtnGrey M_Edit_Update" onClick={() => UpdateAdminUser()}>
                    Update Details
                </button>
                <button className="LogincancelBtn BtnLightGrey" onClick={() => EditUserIsOpen(false)}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

export default EditUserModel;
