import '../Manager/Manager.scss';
import Api from '../../AxiosRest/AxiosRest';
import { useNavigate } from 'react-router-dom';
import {React,useState, useEffect,useRef, startTransition} from "react";
import Chart from 'react-apexcharts';

//State imports
import { useSelector, useDispatch } from 'react-redux';
import { Login, CurrentUser, Product, AddToCart, CurrentProduct, FilteredProduct, AllStores } from '../../Actions';

function Store_Stats(){

  let [stats, setStats] = useState({});
  const [Approved, setApproved] = useState("");
  const [AwaitingApproval, setAwaitingApproval] = useState("");
  const [DeclinedStores, setDeclinedStores] = useState("");
  const [TotalProducts, setTotalProducts] = useState("");
  let SetLabels = [];

  //Firing API call to recieve full list of stats for all stores
  let GetStats = async() =>{
    try{
        await Api.get('/store/Manager/GetManagerStats').then((response) => {
            setStats(response.data);
            setApproved(response.data.approvedStores);
            setAwaitingApproval(response.data.awaitingApproval);
            setDeclinedStores(response.data.declinedStores);
            setTotalProducts(response.data.totalProducts);
            
        });
    }
    catch(exception){   
        
    }  
  }


  const options = {
      labels: ["Approved", "Awaited Approved", "Declined Stores"],
      chart: {
        type: 'donut',
      },
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 90,
          offsetY: 10
        }
      },
      grid: {
        padding: {
          bottom: -80
        }
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    }
    const series = [Approved, AwaitingApproval, DeclinedStores];

    //used to fire api call as soon as the page loads
    useEffect(() => {
        GetStats();
    }, []);

    return(
        <div id="Store_Stats">
            <div>
                <div className="Stats">
                    <div>
                        <h2>Approved Stores:</h2><h2>  {stats.approvedStores}</h2>
                    </div>
                    <div>
                        <h2>Stores awaiting Approval:</h2><h2> {stats.awaitingApproval}</h2>
                    </div>
                    <div>
                        <h2>Total Stores:</h2><h2> {stats.totalStores}</h2>
                    </div>
                    <div>
                        <h2>Number of Products:</h2><h2> {stats.totalProducts}</h2>
                    </div>
                </div>
                <div className="Chart">
                    <Chart options={options} series={series} type="donut" width="600" />
                </div>
            </div>
        </div>
    )
}

export default Store_Stats;